import React, { useState } from 'react';
import { Row, Col, Spin } from 'antd';
import './Course.scss';

function Course2() {
  const [selectedModule, setSelectedModule] = useState(null);
  const [loading, setLoading] = useState(false);

  const modules = [
    {
      title: "¡Bienvenido al Curso 'Trading de Opciones Binarias (Parte 1)' 🌐✨ ",
      description: "Sumérgete en el mundo del trading y descubre las estrategias más efectivas para maximizar tus ganancias y minimizar tus riesgos. Este curso te llevará desde los fundamentos del trading hasta técnicas avanzadas, proporcionándote las herramientas necesarias para tomar decisiones informadas en el mercado financiero. Aprenderás a analizar gráficos, identificar tendencias, y aplicar estrategias de trading exitosas. Desde la gestión del riesgo hasta la ejecución de operaciones, este curso te proporcionará las habilidades y el conocimiento necesario para convertirte en un trader exitoso. <br>" ,
      videos: [
        { url: 'https://drive.google.com/file/d/101rTibv3hIwxP627iKCDtSKfVyvxtlzL/preview?disablekb=1&controls=0&modestbranding=1', title: 'Que es el Trading?' },
        { url: 'https://drive.google.com/file/d/1lVi0iBRh0AlwvG_ZoVMEBDzvyHGgmEN3/preview?disablekb=1&controls=0&modestbranding=1', title: 'Introducción al mercado Forex' },
        { url: 'https://drive.google.com/file/d/1BV8_0mwosUg2MF0IL-nkjaXeQhig-7Be/preview?disablekb=1&controls=0&modestbranding=1', title: '3) Mercado Forex vs. Mercado de Acciones' },
        { url: 'https://drive.google.com/file/d/1RV4m9553O2DMaMa5Jkws337tKWjeUsD2/preview?disablekb=1&controls=0&modestbranding=1', title: '4) Que es el par de divisas_ Que es el pip' },
        { url: 'https://drive.google.com/file/d/1iGNIV4xkNU3F52x49mpzbd3N9RSmyeaA/preview?disablekb=1&controls=0&modestbranding=1', title: 'LAS ETAPAS DEL TRADER _ _BULLFINANZAS' },
        { url: 'https://drive.google.com/file/d/1jd-iBRtB8r78WzkR9-krw2lZ4BTMQoEs/preview?disablekb=1&controls=0&modestbranding=1', title: 'TUTORIAL IQOption - COMO UTILIZAR LA PLATAFORMA' }





      ]
    },
    {
      title: "¡Parte 2' 🌐✨ 🌱 ",
      description: "Parte 2",
      videos: [
        { url: 'https://drive.google.com/file/d/1lVi0iBRh0AlwvG_ZoVMEBDzvyHGgmEN3/preview?disablekb=1&controls=0&modestbranding=1', title: '10) Análisis Fundamental 1' },
        { url: 'https://drive.google.com/file/d/1lVi0iBRh0AlwvG_ZoVMEBDzvyHGgmEN3/preview?disablekb=1&controls=0&modestbranding=1', title: '10) Análisis Fundamental 1' },
        { url: 'https://drive.google.com/file/d/1lVi0iBRh0AlwvG_ZoVMEBDzvyHGgmEN3/preview?disablekb=1&controls=0&modestbranding=1', title: '10) Análisis Fundamental 1' },
        { url: 'https://drive.google.com/file/d/1lVi0iBRh0AlwvG_ZoVMEBDzvyHGgmEN3/preview?disablekb=1&controls=0&modestbranding=1', title: '10) Análisis Fundamental 1' },
        { url: 'https://drive.google.com/file/d/1lVi0iBRh0AlwvG_ZoVMEBDzvyHGgmEN3/preview?disablekb=1&controls=0&modestbranding=1', title: '10) Análisis Fundamental 1' },
        { url: 'https://drive.google.com/file/d/1lVi0iBRh0AlwvG_ZoVMEBDzvyHGgmEN3/preview?disablekb=1&controls=0&modestbranding=1', title: '10) Análisis Fundamental 1' },
        { url: 'https://drive.google.com/file/d/1lVi0iBRh0AlwvG_ZoVMEBDzvyHGgmEN3/preview?disablekb=1&controls=0&modestbranding=1', title: '10) Análisis Fundamental 1' },
        { url: 'https://drive.google.com/file/d/1lVi0iBRh0AlwvG_ZoVMEBDzvyHGgmEN3/preview?disablekb=1&controls=0&modestbranding=1', title: '10) Análisis Fundamental 1' },
        { url: 'https://drive.google.com/file/d/1lVi0iBRh0AlwvG_ZoVMEBDzvyHGgmEN3/preview?disablekb=1&controls=0&modestbranding=1', title: '10) Análisis Fundamental 1' },
        { url: 'https://drive.google.com/file/d/1lVi0iBRh0AlwvG_ZoVMEBDzvyHGgmEN3/preview?disablekb=1&controls=0&modestbranding=1', title: '10) Análisis Fundamental 1' },
        { url: 'https://drive.google.com/file/d/1lVi0iBRh0AlwvG_ZoVMEBDzvyHGgmEN3/preview?disablekb=1&controls=0&modestbranding=1', title: '10) Análisis Fundamental 1' },
        { url: 'https://drive.google.com/file/d/1lVi0iBRh0AlwvG_ZoVMEBDzvyHGgmEN3/preview?disablekb=1&controls=0&modestbranding=1', title: '10) Análisis Fundamental 1' },
        { url: 'https://drive.google.com/file/d/1lVi0iBRh0AlwvG_ZoVMEBDzvyHGgmEN3/preview?disablekb=1&controls=0&modestbranding=1', title: '10) Análisis Fundamental 1' },
        { url: 'https://drive.google.com/file/d/1lVi0iBRh0AlwvG_ZoVMEBDzvyHGgmEN3/preview?disablekb=1&controls=0&modestbranding=1', title: '10) Análisis Fundamental 1' },
        { url: 'https://drive.google.com/file/d/1lVi0iBRh0AlwvG_ZoVMEBDzvyHGgmEN3/preview?disablekb=1&controls=0&modestbranding=1', title: '10) Análisis Fundamental 1' },
        { url: 'https://drive.google.com/file/d/1lVi0iBRh0AlwvG_ZoVMEBDzvyHGgmEN3/preview?disablekb=1&controls=0&modestbranding=1', title: '10) Análisis Fundamental 1' },
        { url: 'https://drive.google.com/file/d/1lVi0iBRh0AlwvG_ZoVMEBDzvyHGgmEN3/preview?disablekb=1&controls=0&modestbranding=1', title: '10) Análisis Fundamental 1' },
        { url: 'https://drive.google.com/file/d/1lVi0iBRh0AlwvG_ZoVMEBDzvyHGgmEN3/preview?disablekb=1&controls=0&modestbranding=1', title: '10) Análisis Fundamental 1' },
        { url: 'https://drive.google.com/file/d/1lVi0iBRh0AlwvG_ZoVMEBDzvyHGgmEN3/preview?disablekb=1&controls=0&modestbranding=1', title: '10) Análisis Fundamental 1' },
        { url: 'https://drive.google.com/file/d/1lVi0iBRh0AlwvG_ZoVMEBDzvyHGgmEN3/preview?disablekb=1&controls=0&modestbranding=1', title: '10) Análisis Fundamental 1' },
        { url: 'https://drive.google.com/file/d/1lVi0iBRh0AlwvG_ZoVMEBDzvyHGgmEN3/preview?disablekb=1&controls=0&modestbranding=1', title: '10) Análisis Fundamental 1' },
        { url: 'https://drive.google.com/file/d/1lVi0iBRh0AlwvG_ZoVMEBDzvyHGgmEN3/preview?disablekb=1&controls=0&modestbranding=1', title: '10) Análisis Fundamental 1' },
        { url: 'https://drive.google.com/file/d/1lVi0iBRh0AlwvG_ZoVMEBDzvyHGgmEN3/preview?disablekb=1&controls=0&modestbranding=1', title: '10) Análisis Fundamental 1' },
        { url: 'https://drive.google.com/file/d/1lVi0iBRh0AlwvG_ZoVMEBDzvyHGgmEN3/preview?disablekb=1&controls=0&modestbranding=1', title: '10) Análisis Fundamental 1' },
        { url: 'https://drive.google.com/file/d/1lVi0iBRh0AlwvG_ZoVMEBDzvyHGgmEN3/preview?disablekb=1&controls=0&modestbranding=1', title: '10) Análisis Fundamental 1' },
        { url: 'https://drive.google.com/file/d/1lVi0iBRh0AlwvG_ZoVMEBDzvyHGgmEN3/preview?disablekb=1&controls=0&modestbranding=1', title: '10) Análisis Fundamental 1' },
        { url: 'https://drive.google.com/file/d/1lVi0iBRh0AlwvG_ZoVMEBDzvyHGgmEN3/preview?disablekb=1&controls=0&modestbranding=1', title: '10) Análisis Fundamental 1' },
        { url: 'https://drive.google.com/file/d/1lVi0iBRh0AlwvG_ZoVMEBDzvyHGgmEN3/preview?disablekb=1&controls=0&modestbranding=1', title: '10) Análisis Fundamental 1' },
        { url: 'https://drive.google.com/file/d/1lVi0iBRh0AlwvG_ZoVMEBDzvyHGgmEN3/preview?disablekb=1&controls=0&modestbranding=1', title: '10) Análisis Fundamental 1' },
        { url: 'https://drive.google.com/file/d/1lVi0iBRh0AlwvG_ZoVMEBDzvyHGgmEN3/preview?disablekb=1&controls=0&modestbranding=1', title: '10) Análisis Fundamental 1' },
        { url: 'https://drive.google.com/file/d/1lVi0iBRh0AlwvG_ZoVMEBDzvyHGgmEN3/preview?disablekb=1&controls=0&modestbranding=1', title: '10) Análisis Fundamental 1' },
        { url: 'https://drive.google.com/file/d/1lVi0iBRh0AlwvG_ZoVMEBDzvyHGgmEN3/preview?disablekb=1&controls=0&modestbranding=1', title: '10) Análisis Fundamental 1' },
        { url: 'https://drive.google.com/file/d/1lVi0iBRh0AlwvG_ZoVMEBDzvyHGgmEN3/preview?disablekb=1&controls=0&modestbranding=1', title: '10) Análisis Fundamental 1' },
        { url: 'https://drive.google.com/file/d/1lVi0iBRh0AlwvG_ZoVMEBDzvyHGgmEN3/preview?disablekb=1&controls=0&modestbranding=1', title: '10) Análisis Fundamental 1' }

      ]
    },
    {
      title: "¡Parte 3' 🌐✨ 🌱 ",
      description: "Parte 3",
      videos: [
        { url: 'https://drive.google.com/file/d/1lVi0iBRh0AlwvG_ZoVMEBDzvyHGgmEN3/preview?disablekb=1&controls=0&modestbranding=1', title: '10) Análisis Fundamental 1' },
        { url: 'https://drive.google.com/file/d/1lVi0iBRh0AlwvG_ZoVMEBDzvyHGgmEN3/preview?disablekb=1&controls=0&modestbranding=1', title: '10) Análisis Fundamental 1' },
        { url: 'https://drive.google.com/file/d/1lVi0iBRh0AlwvG_ZoVMEBDzvyHGgmEN3/preview?disablekb=1&controls=0&modestbranding=1', title: '10) Análisis Fundamental 1' },
        { url: 'https://drive.google.com/file/d/1lVi0iBRh0AlwvG_ZoVMEBDzvyHGgmEN3/preview?disablekb=1&controls=0&modestbranding=1', title: '10) Análisis Fundamental 1' },
        { url: 'https://drive.google.com/file/d/1lVi0iBRh0AlwvG_ZoVMEBDzvyHGgmEN3/preview?disablekb=1&controls=0&modestbranding=1', title: '10) Análisis Fundamental 1' },
        { url: 'https://drive.google.com/file/d/1lVi0iBRh0AlwvG_ZoVMEBDzvyHGgmEN3/preview?disablekb=1&controls=0&modestbranding=1', title: '10) Análisis Fundamental 1' },
        { url: 'https://drive.google.com/file/d/1lVi0iBRh0AlwvG_ZoVMEBDzvyHGgmEN3/preview?disablekb=1&controls=0&modestbranding=1', title: '10) Análisis Fundamental 1' },
        { url: 'https://drive.google.com/file/d/1lVi0iBRh0AlwvG_ZoVMEBDzvyHGgmEN3/preview?disablekb=1&controls=0&modestbranding=1', title: '10) Análisis Fundamental 1' },
        { url: 'https://drive.google.com/file/d/1lVi0iBRh0AlwvG_ZoVMEBDzvyHGgmEN3/preview?disablekb=1&controls=0&modestbranding=1', title: '10) Análisis Fundamental 1' },
        { url: 'https://drive.google.com/file/d/1lVi0iBRh0AlwvG_ZoVMEBDzvyHGgmEN3/preview?disablekb=1&controls=0&modestbranding=1', title: '10) Análisis Fundamental 1' }

      ]
    }
  ];

  const openModule = (module) => {
    setLoading(true);
    setSelectedModule(module);
    setLoading(false);
  };

  const closeModule = () => {
    setSelectedModule(null);
  };

  if (selectedModule) {
    return (
      <div className="module-details">
        <button className="close-button" onClick={closeModule}>Cerrar</button>
        <Col xs={24} sm={12}>
          <h2>{selectedModule.title}</h2>
          <p className="justify-text" dangerouslySetInnerHTML={{ __html: selectedModule.description }} />
        </Col>
        <Row gutter={[16, 16]}>
          {loading ? ( // Mostrar el indicador de carga mientras loading es true
            <Col span={24} style={{ textAlign: 'center' }}>
              <Spin size="large" />
            </Col>
          ) : (
            selectedModule.videos.map((video, index) => (
              <Col xs={24} sm={12} md={8} lg={12} key={index}>
                <div className="video">
                  <h3>{video.title}</h3>
                  <div className="video-container">
                    <iframe
                      src={video.url}
                      frameBorder="0"
                      allowFullScreen
                      title={video.title}
                      width="100%"
                      height="100%"
                      style={{ width: '100%', height: '100%' }}
                    ></iframe>
                  </div>
                </div>
              </Col>
            ))
          )}
        </Row>
      </div>
    );
  }

  return (
    <div className="background-image">
      <div className="container">
        <Row gutter={[16, 16]}>
          {modules.map((module, index) => (
            <Col xs={24} sm={12} md={4} lg={6} key={index}>
              <div className="course" onClick={() => openModule(module)}>
                <h3>{module.title}</h3>
                <div className="preview-image-container">
                  <img
                    src={`../imgs/course3/c${index}.webp`}
                    alt={`Modulo ${index + 1}`}
                    style={{ maxWidth: '100%', cursor: 'pointer' }}
                  />
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
}

export default Course2;
