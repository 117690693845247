import React, { useEffect, useState } from 'react';
import { Result } from 'antd';
import 'antd/dist/antd.dark.css';
import './login.scss';

function Login() {
    const [id, setId] = useState('');

    useEffect(() => {
        const getIDFromURL = () => {
            const urlParams = new URLSearchParams(window.location.search);
            const idFromURL = urlParams.get('id') || 'MrCash'; // Valor por defecto si no se encuentra 'id' en la URL
            setId(idFromURL);
            localStorage.setItem('idValueSponsor', idFromURL); // Almacenar 'id' en el LocalStorage
        };

        getIDFromURL(); // Obtener ID de la URL al cargar la página

        window.addEventListener('popstate', getIDFromURL);

        return () => {
            window.removeEventListener('popstate', getIDFromURL);
        };
    }, []);

    return (
        <Result
            title="Conéctese a su billetera"
            subTitle={`Debes conectarte usando la billetera META MASK para continuar. Actualizaremos la conexión WalletConnect en los próximos días.`}
            icon={
                <div className="background-image2">
                    <div className="image-container">
                        <img src="login2.webp" alt="Set App by future" style={{ maxWidth: '160%', maxHeight: '100%' }} />
                    </div>
                </div>
            }
        />
    );
}

export default Login;
