import React, { useState } from 'react';
import { Row, Col, Spin } from 'antd';
import './Course.scss';

function Trading2() {
  const [selectedModule, setSelectedModule] = useState(null);
  const [loading, setLoading] = useState(false);

  const modules = [
    {
      title: "Modulo 1 Arrancamos",
      description: "Sumérgete en el mundo del trading y descubre las estrategias más efectivas para maximizar tus ganancias y minimizar tus riesgos. Este curso te llevará desde los fundamentos del trading hasta técnicas avanzadas, proporcionándote las herramientas necesarias para tomar decisiones informadas en el mercado financiero. Aprenderás a analizar gráficos, identificar tendencias, y aplicar estrategias de trading exitosas. Desde la gestión del riesgo hasta la ejecución de operaciones, este curso te proporcionará las habilidades y el conocimiento necesario para convertirte en un trader exitoso. <br>" ,
      videos: [
        { url: 'https://drive.google.com/file/d/1CXG6uJcSXDty8HgpAitbPuBUFilWsuVu/preview?disablekb=1&controls=0&modestbranding=1', title: 'Bienvenida y temario' },
        { url: 'https://drive.google.com/file/d/1w1BQQXIdRvdPeqlJ4568NN6Z0ksBWE1v/preview?disablekb=1&controls=0&modestbranding=1', title: 'Temario' },
        { url: 'https://drive.google.com/file/d/1w9YVW4ZLTwX_OPt3khVKW6cCplo7Z4j8/preview?disablekb=1&controls=0&modestbranding=1', title: 'Lo que queremos para ti' }
      ]
    },
    {
      title: "Modulo 2 Riesgos del Trading",
      description: "Riesgos del Trading" ,
      videos: [
        { url: 'https://drive.google.com/file/d/1VrScMirI3EnALYqOQH__cKX3lR0hu5-5/preview?disablekb=1&controls=0&modestbranding=1', title: '1. Riesgos del Trading' },
        { url: 'https://drive.google.com/file/d/1jwCFEUU_4hdheKbEfH2N03VXe-wgbuhl/preview?disablekb=1&controls=0&modestbranding=1', title: '2. Ciclo de aprendizaje' },
        { url: 'https://drive.google.com/file/d/1cJxnhQojQbZzAL9saMdyPbJLMiWKbHnz/preview?disablekb=1&controls=0&modestbranding=1', title: '3. Conclusión' }
      ]
    },
    {
      title: "Modulo 3 Trader Vs Inversionista",
      description: "Trader Vs Inversionista",
      videos: [
        { url: 'https://drive.google.com/file/d/1BHHd-kJ6oM7rJszIMLDPTc1mWEIXMNCJ/preview?disablekb=1&controls=0&modestbranding=1', title: '1. Introducción' },
        { url: 'https://drive.google.com/file/d/1dHWwPYtGweVvNN_IDrZLBOvEC-62nXXB/preview?disablekb=1&controls=0&modestbranding=1', title: '2. ¿Qué es invertir?' },
        { url: 'https://drive.google.com/file/d/14wrZ3a-JDba0UkIVg4-fQPyvR2OxE-Ar/preview?disablekb=1&controls=0&modestbranding=1', title: '3. ¿Por qué debo invertir?' },
        { url: 'https://drive.google.com/file/d/18qScFG5SC6rzClfFThdwQjtsgqU-5fkZ/preview?disablekb=1&controls=0&modestbranding=1', title: '4. ¿Cuándo debo invertir' },
        { url: 'https://drive.google.com/file/d/18UcKzqELTF7TLP8q5ky5Y6gDhaZppx78/preview?disablekb=1&controls=0&modestbranding=1', title: '5.¿Cuánto dinero debo invertir y en qué instrumentos' },
        { url: 'https://drive.google.com/file/d/1Pey5h86tsIaHTJMJFGU8pfmonkDvmBUZ/preview?disablekb=1&controls=0&modestbranding=1', title: '6. ¿Qué es el Trading' },
        { url: 'https://drive.google.com/file/d/13IKcqinosA5WDrNwCfKOKUiKf9SubwJp/preview?disablekb=1&controls=0&modestbranding=1', title: '7. ¿Qué porcentaje de mi portafolio debe ser para Trading' },
        { url: 'https://drive.google.com/file/d/1yNlQTiMddtB8wnPXwHTfHqOzu3UeA-hS/preview?disablekb=1&controls=0&modestbranding=1', title: '8. Diferencias entre hacer Trading e Invertir' },
        { url: 'https://drive.google.com/file/d/1QpkHIJtjDWX4t_Ba3cWgAvwjxpWgEHhx/preview?disablekb=1&controls=0&modestbranding=1', title: '9. El Trader-Inversionista' },
        { url: 'https://drive.google.com/file/d/1uwgyv6tupzb4Ht6MGn9bRX8wepCLnE-_/preview?disablekb=1&controls=0&modestbranding=1', title: '10. Conclusión' }
      ]
    },
    {
      title: "Modulo 4 Calcula tu perfil de riesgo",
      description: "Calcula tu perfil de riesgo",
      videos: [
        { url: 'https://drive.google.com/file/d/1GzIuztEheIBYseXCt-NHJk2kTYlRIhHp/preview?disablekb=1&controls=0&modestbranding=1', title: 'Conoce tu Perfil de Riesgo' }
      ]
    },
    {
      title: "Modulo 5 Abrir y operar cuentas demo",
      description: "Abrir y operar cuentas demo",
      videos: [
        { url: 'https://drive.google.com/file/d/1AE557l4x-0j0Bp-o4SEFEHDcdtVEqW4U/preview?disablekb=1&controls=0&modestbranding=1', title: '1. Empieza aquí' },
        { url: 'https://drive.google.com/file/d/11lshrSyEG8gZQkhqifM3C5gM_JI2lk7i/preview?disablekb=1&controls=0&modestbranding=1', title: '2.1. Apertura cuenta DEMO de Acciones en PC' },
        { url: 'https://drive.google.com/file/d/1ocpGYSpSuktrX6-YaL7mkqB0tGAxMvqv/preview?disablekb=1&controls=0&modestbranding=1', title: '2.2. Apertura cuenta DEMO de Acciones en PC' },
        { url: 'https://drive.google.com/file/d/1EeN1jqAX4V6kThs47MPJmGYr-k7NLV6V/preview?disablekb=1&controls=0&modestbranding=1', title: '3. Opera una cuenta DEMO de Acciones en MT5' },
        { url: 'https://drive.google.com/file/d/1s_GmQ8O0A4FMF2oiHeSDGyRgPi4V38Cb/preview?disablekb=1&controls=0&modestbranding=1', title: '5.¿Cuánto dinero debo invertir y en qué instrumentos' },
        { url: 'https://drive.google.com/file/d/1s_GmQ8O0A4FMF2oiHeSDGyRgPi4V38Cb/preview?disablekb=1&controls=0&modestbranding=1', title: '4.1. Apertura cuenta DEMO en MT4 para Divisas, Índices, Criptos, CFD' },
        { url: 'https://drive.google.com/file/d/1XJ_aCh5cCW-43US_ghH7bX1zf4ZgqPdK/preview?disablekb=1&controls=0&modestbranding=1', title: '4.2. Apertura cuenta DEMO en MT4 para Divisas, Índices, Criptos, CFD' },
        { url: 'https://drive.google.com/file/d/1b3bX_rJPYDRP-DXEtQk5te5_te4HLfuF/preview?disablekb=1&controls=0&modestbranding=1', title: '4.3. Apertura cuenta DEMO en MT4 para Divisas, Índices, Criptos, CFD' },
        { url: 'https://drive.google.com/file/d/11e80aZ9mrQu8xXQKkX262GxzEnQJFniJ/preview?disablekb=1&controls=0&modestbranding=1', title: '5. Opera cuenta DEMO en MT4 para Divisas, Índices, Criptos, CFD' },
        { url: 'https://drive.google.com/file/d/16ipUVY-Br_o5n_0PFdE6NhyWT4U5dpq0/preview?disablekb=1&controls=0&modestbranding=1', title: '6.1. Futuros en BINANCE DEMO - LIVE' },
        { url: 'https://drive.google.com/file/d/1irvCNE3xCtjbv-gZiwf_7sSMKT97y8f2/preview?disablekb=1&controls=0&modestbranding=1', title: '6.2. Apertura cuenta DEMO en MT4 para Divisas, Índices, Criptos, CFD.pdf' }
      ]
    },
    {
      title: "Modulo 6 El mercado y la acción del precio",
      description: "El mercado y la acción del precio",
      videos: [
        { url: 'https://drive.google.com/file/d/15ss62yYvm3krlKya4eVXB4I4fhbVcryc/preview?disablekb=1&controls=0&modestbranding=1', title: '1. ¿Qué es el mercado' },
        { url: 'https://drive.google.com/file/d/1NhLMpfsOLO7g3p81LiIzTaMIaCV2cV0Q/preview?disablekb=1&controls=0&modestbranding=1', title: '2. ¿Cuáles son los mercados financieros' },
        { url: 'https://drive.google.com/file/d/15rYNbBRYzwcbA1CfDy41BW_5sarxyF2K/preview?disablekb=1&controls=0&modestbranding=1', title: '3. La acción del precio en las velas japonesas' },
        { url: 'https://drive.google.com/file/d/1jP2M-ACEv-SFFTVKH3t-GviQkbmcvqQY/preview?disablekb=1&controls=0&modestbranding=1', title: '4. Bid, Ask y Spread' },
        { url: 'https://drive.google.com/file/d/1Lofc6rDP3Qn98lD0RS63QUeW62HUtV2o/preview?disablekb=1&controls=0&modestbranding=1', title: '6. Tipos de operaciones largo y corto' },
        { url: 'https://drive.google.com/file/d/1sib2EdUTOBYZXx1H8vpg2nfJvF28xLFi/preview?disablekb=1&controls=0&modestbranding=1', title: '7. ¿Qué es el apalancamiento' },
        { url: 'https://drive.google.com/file/d/1Tfv7GOOJsJETnfAwHnOKFfW6ob7B69YR/preview?disablekb=1&controls=0&modestbranding=1', title: '8. Las órdenes y sus tipos' },
        { url: 'https://drive.google.com/file/d/132c0Je9YsIQdIVF1PChDIbQ40Ccaz0xJ/preview?disablekb=1&controls=0&modestbranding=1', title: '9. Los pares, todo lo que debes saber' },
        { url: 'https://drive.google.com/file/d/1I_YSJ_bDwm9HYXrkvCBZiJy2lWFyx9hG/preview?disablekb=1&controls=0&modestbranding=1', title: '10. ¿Qué es un pip' }
      ]
    },
    {
      title: "Modulo 7 Análisis técnico",
      description: "Análisis técnico",
      videos: [
        { url: 'https://drive.google.com/file/d/14alxIrt6WamfqvCDfw61D2r0i0e1QTvd/preview?disablekb=1&controls=0&modestbranding=1', title: '1. ¿Qué es el análisis técnico' },
        { url: 'https://drive.google.com/file/d/1OROPnq3d7fwoKh3DRnlfiw3kFsdTdiJa/preview?disablekb=1&controls=0&modestbranding=1', title: '2. La tendencia' },
        { url: 'https://drive.google.com/file/d/1AzLqM9065-pK36Y6PfvGP_T_ijhrQN0f/preview?disablekb=1&controls=0&modestbranding=1', title: '3. Soportes y resistencias' },
        { url: 'https://drive.google.com/file/d/1tDdQNyxpV8DJgw30syja59nKqicW8dVE/preview?disablekb=1&controls=0&modestbranding=1', title: '4. Rompimientos' },
        { url: 'https://drive.google.com/file/d/1yIvGUEHrGYsgl0ZWOkxEq3pXFDn_tiXC/preview?disablekb=1&controls=0&modestbranding=1', title: '5. Ejercicios de soportes y resistencias' },
        { url: 'https://drive.google.com/file/d/18xba9yGvMI34TjXUiKkR3JxNggSy_s3V/preview?disablekb=1&controls=0&modestbranding=1', title: '6. El mercado es un fractal' }
      ]
    },
    {
      title: "Modulo 8 El sistema trading monsters",
      description: "El sistema trading monsters",
      videos: [
        { url: 'https://drive.google.com/file/d/1UGSmwBTezbrMbrCLAqHBkwHajYCZ5zqz/preview?disablekb=1&controls=0&modestbranding=1', title: '1. Introducción' },
        { url: 'https://drive.google.com/file/d/1nhvACP152c2yPY-ffytmkPuQ_cfWVC12/preview?disablekb=1&controls=0&modestbranding=1', title: '02. La Tendencia Perfecta' },
        { url: 'https://drive.google.com/file/d/1qVeh1fjv4fsXd454L77JGnJkIoKRyVU3/preview?disablekb=1&controls=0&modestbranding=1', title: '03. El Doble Ascenso y Descenso' },
        { url: 'https://drive.google.com/file/d/17VNKQxSQqXZTCbRofLlyz9yxlGsjotcN/preview?disablekb=1&controls=0&modestbranding=1', title: '04. Los Momentos de Compra' },
        { url: 'https://drive.google.com/file/d/112vaLqcvIp5CCF1qOX2rFVw0LQJYIAOy/preview?disablekb=1&controls=0&modestbranding=1', title: '05. Los Momentos de Venta' },
        { url: 'https://drive.google.com/file/d/1bi8ZRM4hZMnF0D9yaW7zOmOSyo465k8N/preview?disablekb=1&controls=0&modestbranding=1', title: '06. ¿Dónde colocar el Stop Loss' },
        { url: 'https://drive.google.com/file/d/1V0p1pHYlaYycQtRSnm7RNvwFCi3705w5/preview?disablekb=1&controls=0&modestbranding=1', title: '07.1. Ejercicios de Compra y Venta' },
        { url: 'https://drive.google.com/file/d/1Vp9-zr53wddJZpDON2PE44HXwowWFlQ6/preview?disablekb=1&controls=0&modestbranding=1', title: '08.1. Cambio de Tendencia' },
        { url: 'https://drive.google.com/file/d/1kc-FUnO3bnbr30YJi5dihwEkbFKV76CE/preview?disablekb=1&controls=0&modestbranding=1', title: '09. Doble Techo o M' },
        { url: 'https://drive.google.com/file/d/1d_Xs2UCONLUiHpQUAj31FGhOEum6xsMm/preview?disablekb=1&controls=0&modestbranding=1', title: '10. Doble Fondo o W' },
        { url: 'https://drive.google.com/file/d/1IUQKYqmXWMCBCUO3FSXq0b5RTJgmgU1t/preview?disablekb=1&controls=0&modestbranding=1', title: '11. Ejercicios Cambio de Tendencia' },
        { url: 'https://drive.google.com/file/d/1JNMGXxb-mzeTD_6Xop0YKwnH96iWVKFJ/preview?disablekb=1&controls=0&modestbranding=1', title: '12. La Importancia de los Costos' },
        { url: 'https://drive.google.com/file/d/1pSQo2mSgKx-4jW3C2Us9joA-D38huihw/preview?disablekb=1&controls=0&modestbranding=1', title: '13. Tipos de Trading' },
        { url: 'https://drive.google.com/file/d/1bsaSYK_HB9GxI4yjAVAL2H4umG7gwG1p/preview?disablekb=1&controls=0&modestbranding=1', title: '14. ¿Qué escala de tiempo utilizar' },
        { url: 'https://drive.google.com/file/d/1E_xOSqS6JGHZm1GySCPAf_RkezDsHmNd/preview?disablekb=1&controls=0&modestbranding=1', title: '15. ¿Cuándo comprar' },
        { url: 'https://drive.google.com/file/d/16J_VzKNLovCjA08tuisbZg7mUALr3mK7/preview?disablekb=1&controls=0&modestbranding=1', title: '16. ¿Cómo mover el Stop Loss' },
        { url: 'https://drive.google.com/file/d/1vjiJvomkl1nYMTXXtIGpVT6ER99l9bIn/preview?disablekb=1&controls=0&modestbranding=1', title: '17. ¿Cómo colocar un Take Profit' },
        { url: 'https://drive.google.com/file/d/1hQgPTKuSRLN4Lh5oCelFtZqKSOSMAkYD/preview?disablekb=1&controls=0&modestbranding=1', title: '18.1. Horarios de Trading' },
        { url: 'https://drive.google.com/file/d/1Ydbq7FqSaSEyaPJ5TIQR6qOFeL65qx6f/preview?disablekb=1&controls=0&modestbranding=1', title: '19. Costo Real de una Pérdida' },
        { url: 'https://drive.google.com/file/d/1UBfrGtCDphbndGT3c2UOPqT14QM5TCsk/preview?disablekb=1&controls=0&modestbranding=1', title: '20. Calculadora de Riesgo' },
        { url: 'https://drive.google.com/file/d/1fOvesbJtfQflxQKrJm7Ud6r-Qgs1xLyP/preview?disablekb=1&controls=0&modestbranding=1', title: '21. Calculadora de Posición MT4' },
        { url: 'https://drive.google.com/file/d/1ESdv1cSQiHHGalMbqHd_BbCgZ2p2viAs/preview?disablekb=1&controls=0&modestbranding=1', title: '22. Calculadora de Posición MT5' },
        { url: 'https://drive.google.com/file/d/1Qk_-4HLhqr0Nfl4pWjW0CJVph8dV9i3W/preview?disablekb=1&controls=0&modestbranding=1', title: '23. Medias Móviles' },
        { url: 'https://drive.google.com/file/d/1RjZ4CDUVsCEbe2oVSS1YJuntRuUA4XF-/preview?disablekb=1&controls=0&modestbranding=1', title: '24.1. Order Book y Position Book (LIVE)' }
      ]
    },
    {
      title: "Modulo 9 Ejercicios",
      description: "Ejercicios",
      videos: [
        { url: 'https://drive.google.com/file/d/1RAqj4FGCLcwDEyixKAF8CbvtSshnOqBw/preview?disablekb=1&controls=0&modestbranding=1', title: '1.1. Noticia Cambio Tasa AUD' },
        { url: 'https://drive.google.com/file/d/1zkGjr2Wm_NyXoUM7Se9kLEzibzjT6go1/preview?disablekb=1&controls=0&modestbranding=1', title: '2. Tradeando Nonfarm Payrolls' },
        { url: 'https://drive.google.com/file/d/1xfypwWng_pT5MV9W1OUZkCFt-uXSpDHR/preview?disablekb=1&controls=0&modestbranding=1', title: '3. Non Farm en VIVO' }
      ]
    },
    {
      title: "Modulo 10 Criptomonedas",
      description: "Criptomonedas",
      videos: [
        { url: 'https://drive.google.com/file/d/1JlMDJlAyVL1EHUOxfkZiRpy7LiNtt_MU/preview?disablekb=1&controls=0&modestbranding=1', title: '1. El Ciclo de la Dominancia de BTC' },
        { url: 'https://drive.google.com/file/d/1xfybaNdwloYzqq5qKMFG5L8ttEmzzAQK/preview?disablekb=1&controls=0&modestbranding=1', title: '2. Un Ave María en Criptos' }
      ]
    },
    {
      title: "Modulo 11 Manejo del riesgo",
      description: "Manejo del riesgo",
      videos: [
        { url: 'https://drive.google.com/file/d/1A0xVmKoYLi2K7DG3S1KzbhynGNtav7pr/preview?disablekb=1&controls=0&modestbranding=1', title: 'Manejo de Riesgo en Capitales Pequeños' }
      ]
    }
  ];

  const openModule = (module) => {
    setLoading(true);
    setSelectedModule(module);
    setLoading(false);
  };

  const closeModule = () => {
    setSelectedModule(null);
  };

  if (selectedModule) {
    return (
      <div className="module-details">
        <button className="close-button" onClick={closeModule}>Cerrar</button>
        <Col xs={24} sm={12}>
          <h2>{selectedModule.title}</h2>
          <p className="justify-text" dangerouslySetInnerHTML={{ __html: selectedModule.description }} />
        </Col>
        <Row gutter={[16, 16]}>
          {loading ? ( // Mostrar el indicador de carga mientras loading es true
            <Col span={24} style={{ textAlign: 'center' }}>
              <Spin size="large" />
            </Col>
          ) : (
            selectedModule.videos.map((video, index) => (
              <Col xs={24} sm={12} md={8} lg={12} key={index}>
                <div className="video">
                  <h3 style={{ fontSize: '29px', fontWeight: 'bold' }}>{video.title}</h3>
                  <div className="video-container">
                    <iframe
                      src={video.url}
                      frameBorder="0"
                      allowFullScreen
                      title={video.title}
                      width="100%"
                      height="100%"
                      style={{ width: '100%', height: '100%' }}
                    ></iframe>
                  </div>
                </div>
              </Col>
            ))
          )}
        </Row>
      </div>
    );
  }

  return (
    <div className="background-image">
      <div className="container">
        <Row gutter={[16, 16]}>
          {modules.map((module, index) => (
            <Col xs={24} sm={12} md={4} lg={6} key={index}>
              <div className="course" onClick={() => openModule(module)}>
                <h3>{module.title}</h3>
                <div className="preview-image-container">
                  <img
                    src={`../imgs/trading2/c${index}.webp`}
                    alt={`Modulo ${index + 1}`}
                    style={{ maxWidth: '100%', cursor: 'pointer' }}
                  />
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
}

export default Trading2;
