import React, { Component } from 'react'
import 'antd/dist/antd.dark.css'
import {
    Descriptions,
    Button,
    Row,
    message,
    Modal,
    Input
} from 'antd'

import './Dashboard.scss'
import { Web3Instance } from '../util/web3Instance'
import MetaDappContract from '../contracts/CashGroup'
import MetaDappFactory from '../factory/metadappfactory'
import { _sections, _base64, _mapImagesProduct, isConnected, bnbPrice } from '../util/metadapp'
import Login from './Login'
import { InitialState } from '../util/consts'
import { _bnbToWei, _toBigNumber } from '../util/units'

export default class Account extends Component {


    constructor(props) {
        super(props)
        this.state = InitialState.Account

    }
    resetState() {
        this.setState(InitialState.Account)
    }

    componentWillUnmount() {
        this.resetState()
    }

    disconnect() {
        this._web3Instance.disconnect()
        this.resetState()
    }


    checkConnection() {
        if (isConnected()) {
            this.load()
        } else this.props.history.push('/login')
    }

    async componentDidMount() {
        this._web3Instance = await new Web3Instance().init()
        this.checkConnection()
    }

    async load() {
        const idValue = localStorage.getItem('idValueSponsor');
        this.setState({
            account: this._web3Instance.getAccount(),
            id: idValue,

        }, async () => {
            const MetaDapp = await MetaDappContract(this._web3Instance.getProvider())
            this._MetaDappFactory = new MetaDappFactory(MetaDapp)
            this.setState({
                userData: (await this._MetaDappFactory._getUser(this.state.account)),
            })
        })
    }

    onChange = e => {
        this.setState({
            input_name: e.target.value
        })
    }

    async addUser() {
        this.setState({
            isProductModalVisible: true,
            modalTitle: 'Nuevo Registro'
        })
    }

    getFooterButtons() {
        if (this.state.product_confirmed) {
            return [
                this.getAddButton()
            ]
        } else
            return [
                <Button key="ok" shape="round" type="default" onClick={async () => { this.confirm() }}>Confirmar</Button>
            ]
    }

    async confirm() {
        if (this.userValid())
            this.setState({
                product_confirmed: true
            })
        else message.error({ content: `Debes completar todos los campos.`, key: this.state.account, duration: 3 })
    }

    getAddButton() {
        return (
            <Button key="add" shape="round" type="primary" onClick={() => { this.__callAddUser() }}>Crear</Button>
        )
    }
    userValid() {
        return this.state.product_name !== ''
    }


    async __callAddUser() {
        if (this.userValid()) {
            const hideLoad = message.loading(`Agregando el usuario ${this.state.product_name}, Espere un momento por favor...`, 0)
            try {

                const register = await this._MetaDappFactory._register(
                    this.state.account,
                    this.state.product_name,
                    this.state.id,
                    this.state.account
                )

                if (register) {
                    message.success({ content: `¡Usuario creado exitosamente!`, key: this.state.account, duration: 2, mask: true })
                    this.setState({
                        isProductModalVisible: false

                    }, () => {
                        hideLoad()
                        this.props.history.push('/buy');
                        message.success({ content: `¡Usuario creado exitosamente!`, key: this.state.account, duration: 2, mask: true })

                    })
                }
                else {
                    hideLoad()
                    message.error({ content: `Verifique el nombre de usuario de su patrocinador`, key: this.state.account, duration: 2, mask: true })
                }

            } catch (err) {
                hideLoad()
                message.error({ content: 'Se ha producido un error.', key: this.state.account, duration: 2, mask: true });
            }

        } else {
            message.error({ content: `Debes completar todos los campos.`, key: this.state.account, duration: 2, mask: true })
        }
    }
    onProductNameChange = e => {
        const value = e.target.value;
        const regex = /^[a-zA-Z0-9_\s]*$/;

        if (regex.test(value)) {
            this.setState({
                product_name: value,
                product_confirmed: false,
            });
        }
    }



    render() {
        if (isConnected())
            return <>
                <div className="background-image">
                    <Row gutter={16}>
                        <Descriptions title="Registro de usuario" layout="vertical" bordered>
                            <Descriptions.Item label="Registro"> <Button onClick={async () => this.addUser()} shape="round" style={{ margin: '10px' }} type="primary">INSCRIBIRSE</Button></Descriptions.Item>
                        </Descriptions>
                    </Row>
                </div>
                <Modal
                    footer={this.getFooterButtons()}
                    title={this.state.modalTitle}
                    open={this.state.isProductModalVisible}
                    onCancel={() => {
                        this.setState({
                            isProductModalVisible: false
                        })
                    }}>
                    <Row style={{ margin: '10px' }}>
                        <span className="buy">
                            Su patrocinador es: <span className="sponsor">{this.state.id}</span>?
                            <br></br>
                            <br></br>
                        </span>
                        <Input type="hidden" name="action" value={this.state.id} />

                        <Input
                            value={this.state.product_name}
                            onChange={this.onProductNameChange}
                            size="large"
                            placeholder="Ingrese su Username" />
                    </Row>
                </Modal>
            </>
        else return <Login />
    }
}