import React, { useState } from 'react';
import { Row, Col, Spin } from 'antd';
import './Course.scss';



function Idiomas1() {
  const [selectedModule, setSelectedModule] = useState(null);
  const [loading, setLoading] = useState(false);

  const modules = [
    {
      title: "¡Bienvenido al Curso 'Diseño de Páginas de Venta' 🌐✨ ",
      description: "¡Bienvenido al curso de inglés! Este curso está diseñado para estudiantes de todos los niveles, desde básico hasta avanzado. Aprenderás gramática, vocabulario y habilidades de conversación que te ayudarán a mejorar tu inglés de manera efectiva y divertida. ¡Comencemos esta emocionante aventura de aprendizaje juntos! <br>" +

        "<br><br>" +
        '<a Target="_blank" href="https://mega.nz/folder/TeREnIKQ#larLQPTZveXy_MCxpD-LMA">Clic aquí para ingresar a los diferentes modulos</a><br><br>',
      image: `../imgs/idiomas1/c0.webp`
    }
  ];

  const openModule = (module) => {
    setLoading(true);
    setSelectedModule(module);
    setLoading(false);
  };

  const closeModule = () => {
    setSelectedModule(null);
  };

  if (selectedModule) {
    return (
      <div className="module-details">

        <button className="close-button" onClick={closeModule}>Cerrar</button>
        <Col xs={24} sm={12}>
          <h2>{selectedModule.title}</h2>
          <p className="justify-text" dangerouslySetInnerHTML={{ __html: selectedModule.description }} />
        </Col>

      </div>
    );
  }

  return (
    <div className="background-image">
      <div className="container">
        <Row gutter={[16, 16]}>
          {modules.map((module, index) => (
            <Col xs={24} sm={12} md={4} lg={6} key={index}>
              <div className="course" onClick={() => openModule(module)}>
                <h3 >{module.title}</h3>
                <div className="preview-image-container">
                  <img
                    src={module.image}
                    alt={`Modulo ${index + 1}`}
                    style={{ maxWidth: '100%', cursor: 'pointer' }}
                  />
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
}

export default Idiomas1;