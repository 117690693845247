import React, { useState } from 'react';
import { Row, Col, Spin } from 'antd';
import './Course.scss';

function Trading1() {
  const [selectedModule, setSelectedModule] = useState(null);
  const [loading, setLoading] = useState(false);

  const modules = [
    {
      title: "Trading de Opciones Binarias (Parte 1)",
      description: "Sumérgete en el mundo del trading y descubre las estrategias más efectivas para maximizar tus ganancias y minimizar tus riesgos. Este curso te llevará desde los fundamentos del trading hasta técnicas avanzadas, proporcionándote las herramientas necesarias para tomar decisiones informadas en el mercado financiero. Aprenderás a analizar gráficos, identificar tendencias, y aplicar estrategias de trading exitosas. Desde la gestión del riesgo hasta la ejecución de operaciones, este curso te proporcionará las habilidades y el conocimiento necesario para convertirte en un trader exitoso. <br>" ,
      videos: [
        { url: 'https://drive.google.com/file/d/101rTibv3hIwxP627iKCDtSKfVyvxtlzL/preview?disablekb=1&controls=0&modestbranding=1', title: 'Que es el Trading?' },
        { url: 'https://drive.google.com/file/d/1lVi0iBRh0AlwvG_ZoVMEBDzvyHGgmEN3/preview?disablekb=1&controls=0&modestbranding=1', title: 'Introducción al mercado Forex' },
        { url: 'https://drive.google.com/file/d/1BV8_0mwosUg2MF0IL-nkjaXeQhig-7Be/preview?disablekb=1&controls=0&modestbranding=1', title: '3) Mercado Forex vs. Mercado de Acciones' },
        { url: 'https://drive.google.com/file/d/1RV4m9553O2DMaMa5Jkws337tKWjeUsD2/preview?disablekb=1&controls=0&modestbranding=1', title: '4) Que es el par de divisas_ Que es el pip' },
        { url: 'https://drive.google.com/file/d/1iGNIV4xkNU3F52x49mpzbd3N9RSmyeaA/preview?disablekb=1&controls=0&modestbranding=1', title: 'LAS ETAPAS DEL TRADER _ _BULLFINANZAS' },
        { url: 'https://drive.google.com/file/d/1jd-iBRtB8r78WzkR9-krw2lZ4BTMQoEs/preview?disablekb=1&controls=0&modestbranding=1', title: 'TUTORIAL IQOption - COMO UTILIZAR LA PLATAFORMA' }
      ]
    },
    {
      title: "Trading de Opciones Binarias (Parte 2) ",
      description: "Parte 2",
      videos: [
        { url: 'https://drive.google.com/file/d/1vIbtjtwuATMS2434T0-VfqhRpX38XxQz/preview?disablekb=1&controls=0&modestbranding=1', title: '10) Análisis Fundamental 1' },
        { url: 'https://drive.google.com/file/d/1m33RP5X1emDo8AiAy33A2_7ASetA0PMb/preview?disablekb=1&controls=0&modestbranding=1', title: '10) Análisis Fundamental 2' },
        { url: 'https://drive.google.com/file/d/1m33RP5X1emDo8AiAy33A2_7ASetA0PMb/preview?disablekb=1&controls=0&modestbranding=1', title: '10) Análisis Fundamental 3' },
        { url: 'https://drive.google.com/file/d/1z7k-qbWzecUUBysesipPcnwicKH9Azyj/preview?disablekb=1&controls=0&modestbranding=1', title: '11) Introducción al chartismo' },
        { url: 'https://drive.google.com/file/d/1OSoNYysoi0S5Oy7q5zKntWTg1qWl-vuW/preview?disablekb=1&controls=0&modestbranding=1', title: '12) Tipos de gráficos 2' },
        { url: 'https://drive.google.com/file/d/150wF7uUKEDIg5QLjCz63C6j4uqPT0ngw/preview?disablekb=1&controls=0&modestbranding=1', title: '13) La tendencia' },
        { url: 'https://drive.google.com/file/d/1n95ZxIdbGPYDOyOgI_Gp33dTDFDRnCYG/preview?disablekb=1&controls=0&modestbranding=1', title: '15) Lineas de tendencia' },
        { url: 'https://drive.google.com/file/d/1IxXVTDxhCMUTj9pFWgXDGbVCh1FLW_wf/preview?disablekb=1&controls=0&modestbranding=1', title: '16) Canales' },
        { url: 'https://drive.google.com/file/d/1YlCHODkGI72GvYRrCGawEOAO3HwHg2VL/preview?disablekb=1&controls=0&modestbranding=1', title: '17) Triángulos' },
        { url: 'https://drive.google.com/file/d/1PBQBwTRBYA3MWruARbqasb-ARBnO7Tec/preview?disablekb=1&controls=0&modestbranding=1', title: '18) Banderas y Rectángulos' },
        { url: 'https://drive.google.com/file/d/1DE8_f28ZZ-a77-ypxVocVbXMYbivi_ud/preview?disablekb=1&controls=0&modestbranding=1', title: '19) Figuras de Cambio de tendencia (parte 1)' },
        { url: 'https://drive.google.com/file/d/1hwBmBHdBJ2DUQHp68RgN2J1y78c0diWI/preview?disablekb=1&controls=0&modestbranding=1', title: '20) Figuras de cambio de tendencia (parte 2)' },
        { url: 'https://drive.google.com/file/d/1JLubDsY6rLkq-MynMUNZUgQvPw_KCoL0/preview?disablekb=1&controls=0&modestbranding=1', title: '21) Indicadores - MACD 10' },
        { url: 'https://drive.google.com/file/d/1p8ol74p3-l3neu01I5N3zzQRpTz-gXE4/preview?disablekb=1&controls=0&modestbranding=1', title: '21) Rebote técnico' },
        { url: 'https://drive.google.com/file/d/1vNfsfgzt3TR6czbvpKW9yRFgOLYqlyFJ/preview?disablekb=1&controls=0&modestbranding=1', title: '22) Gaps' },
        { url: 'https://drive.google.com/file/d/1j1GtKh0V7rbyRpy0bHkfTrzNARkL0FIh/preview?disablekb=1&controls=0&modestbranding=1', title: '23) Velas Japonesas 1' },
        { url: 'https://drive.google.com/file/d/1H8QKSQVMogfhA1sr_LJ098GDHNlZN8wg/preview?disablekb=1&controls=0&modestbranding=1', title: '23) Velas japonesas 2' },
        { url: 'https://drive.google.com/file/d/1VuZw6Y266GIk38AlClFUdoxHnRhG0dtr/preview?disablekb=1&controls=0&modestbranding=1', title: '23) Velas japonesas 3' },
        { url: 'https://drive.google.com/file/d/12xD_3JXRuM4vFPuPM1zw70iTYTssK3_w/preview?disablekb=1&controls=0&modestbranding=1', title: '23) Velas japonesas 4' },
        { url: 'https://drive.google.com/file/d/160ya65Pj60qfu-p_7ylgE7AYqKhlq8BJ/preview?disablekb=1&controls=0&modestbranding=1', title: '23) Velas japonesas 5' },
        { url: 'https://drive.google.com/file/d/1PeptLE7xEoCb7echu6js1kJeMAUIoyRe/preview?disablekb=1&controls=0&modestbranding=1', title: '23) Velas japonesas 6' },
        { url: 'https://drive.google.com/file/d/1xBR4UUF_qe-K8VFnLy0yrC-TTIQf5Cav/preview?disablekb=1&controls=0&modestbranding=1', title: '24) Indicadores - 2 MM' },
        { url: 'https://drive.google.com/file/d/16T4eI2B9sQpiYbjy1xagqrSV7_U-DDG8/preview?disablekb=1&controls=0&modestbranding=1', title: '24) Indicadores - Bollinger 5' },
        { url: 'https://drive.google.com/file/d/14P2ng0GWXF_gOiy9pEVDbwKee1lVak5_/preview?disablekb=1&controls=0&modestbranding=1', title: '24) Indicadores - Estoc 9' },
        { url: 'https://drive.google.com/file/d/1nL5f8x-4R0SUT0gUnLRIJIduAVKHye6q/preview?disablekb=1&controls=0&modestbranding=1', title: '24) Indicadores - Estocástico 8' },
        { url: 'https://drive.google.com/file/d/1lVyN2SmAO9GYx7FF113_9_ZbUlZe6P1B/preview?disablekb=1&controls=0&modestbranding=1', title: '24) Indicadores - Fibonacci 13' },
        { url: 'https://drive.google.com/file/d/123Uhmhw4mns9LsPtmdmPBBfQjlrZipey/preview?disablekb=1&controls=0&modestbranding=1', title: '24) Indicadores - Fibonacci 14' },
        { url: 'https://drive.google.com/file/d/1K3Ru0G2YJVUHBaNRfgQ2jF1Owu6obHSh/preview?disablekb=1&controls=0&modestbranding=1', title: '24) Indicadores - MM 3' },
        { url: 'https://drive.google.com/file/d/16-kyr60UsDRztSpoaOUDiw16oW9ZmNqo/preview?disablekb=1&controls=0&modestbranding=1', title: '24) Indicadores - MM 4(360P)' },
        { url: 'https://drive.google.com/file/d/1iU5T0IbsF2DK03BFIKGwV3zAdFko_Byc/preview?disablekb=1&controls=0&modestbranding=1', title: '24) Indicadores - RSI 6' },
        { url: 'https://drive.google.com/file/d/1uttnLJ2gR02I5KyzyWrHpvV5C_gSez6W/preview?disablekb=1&controls=0&modestbranding=1', title: '24) Indicadores 1' },
        { url: 'https://drive.google.com/file/d/1mbPwjkodfIxFdcoXiY9bLPC5A2jDz771/preview?disablekb=1&controls=0&modestbranding=1', title: '24) MACD 12' },
        { url: 'https://drive.google.com/file/d/1r4mdjwM1uXzDHzmccnGdkMTbj4RTEyO6/preview?disablekb=1&controls=0&modestbranding=1', title: '24) RSI 2' }

      ]
    },
    {
      title: "Trading de Opciones Binarias (Parte 3)",
      description: "Parte 3",
      videos: [
        { url: 'https://drive.google.com/file/d/1U-jaXUO66cDlULw_mGPyTB8bJCoj2uzW/preview?disablekb=1&controls=0&modestbranding=1', title: '27) Psicotrading 1a' },
        { url: 'https://drive.google.com/file/d/1bzKJSH2-zPago4yk1LJd8hqxfcOL-1Ui/preview?disablekb=1&controls=0&modestbranding=1', title: '27) Psicotrading 1b' },
        { url: 'https://drive.google.com/file/d/1zc_Hvg8Y0Zdcgaf6vnVHdprZThxXX_x8/preview?disablekb=1&controls=0&modestbranding=1', title: '27) Psicotrading 1c' },
        { url: 'https://drive.google.com/file/d/1v6mkIHe6C7mEOExrEHP8urdEUneIwK6k/preview?disablekb=1&controls=0&modestbranding=1', title: '28) Psicotrading 2a' },
        { url: 'https://drive.google.com/file/d/1dKvEJXzx154hWnOCb3GWncaDXDKu6bEE/preview?disablekb=1&controls=0&modestbranding=1', title: '28) Psicotrading 2b' },
        { url: 'https://drive.google.com/file/d/1TDJYPcai_n-MvUbll5WIhGCz3eHmGf5K/preview?disablekb=1&controls=0&modestbranding=1', title: 'PSICOTRADING PRIMERA CLASE' }

      ]
    }
  ];

  const openModule = (module) => {
    setLoading(true);
    setSelectedModule(module);
    setLoading(false);
  };

  const closeModule = () => {
    setSelectedModule(null);
  };

  if (selectedModule) {
    return (
      <div className="module-details">
        <button className="close-button" onClick={closeModule}>Cerrar</button>
        <Col xs={24} sm={12}>
          <h2>{selectedModule.title}</h2>
          <p className="justify-text" dangerouslySetInnerHTML={{ __html: selectedModule.description }} />
        </Col>
        <Row gutter={[16, 16]}>
          {loading ? ( // Mostrar el indicador de carga mientras loading es true
            <Col span={24} style={{ textAlign: 'center' }}>
              <Spin size="large" />
            </Col>
          ) : (
            selectedModule.videos.map((video, index) => (
              <Col xs={24} sm={12} md={8} lg={12} key={index}>
                <div className="video">
                  <h3 style={{ fontSize: '29px', fontWeight: 'bold' }}>{video.title}</h3>
                  <div className="video-container">
                    <iframe
                      src={video.url}
                      frameBorder="0"
                      allowFullScreen
                      title={video.title}
                      width="100%"
                      height="100%"
                      style={{ width: '100%', height: '100%' }}
                    ></iframe>
                  </div>
                </div>
              </Col>
            ))
          )}
        </Row>
      </div>
    );
  }

  return (
    <div className="background-image">
      
      <div className="container">
        <Row gutter={[16, 16]}>
          {modules.map((module, index) => (
            <Col xs={24} sm={12} md={4} lg={6} key={index}>
              <div className="course" onClick={() => openModule(module)}>
                <h3>{module.title}</h3>
                <div className="preview-image-container">
                  <img
                    src={`../imgs/trading1/c${index}.webp`}
                    alt={`Modulo ${index + 1}`}
                    style={{ maxWidth: '100%', cursor: 'pointer' }}
                  />
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
}

export default Trading1;
