import React, { Component } from 'react'
import 'antd/dist/antd.dark.css'
import {
  Descriptions,
  Badge,
  Card,
  Button,
  Row,
  Tag,
  Statistic,
  message,
  Modal,
  Input,
  Select,
  Upload, Progress, Space,
  Col, Spin
} from 'antd'

import './Dashboard.scss'
//import { ForceGraph2D } from 'react-force-graph';
import { Tree } from 'react-d3-tree';

import { Web3Instance } from '../util/web3Instance'
import MetaDappContract from '../contracts/CashGroup'
import MetaDappFactory from '../factory/metadappfactory'
import 'react-tree-graph/dist/style.css'

import { _sections, _base64, _mapImagesProduct, isConnected, bnbPrice } from '../util/metadapp'
import { saveImages, _saveImages } from '../util/imagesaver'

import Login from './Login'

import { InitialState } from '../util/consts'
import { _bnbToWei, _toBigNumber } from '../util/units'
import ipfs from '../storage/ipfs'

export default class Team extends Component {

  constructor(props) {
    super(props)
    this.state = {
      ...InitialState.Account,
      loading: true,
      account: null,
      userData: null,
      totalProducts: null,
      totalUsers: null,
    };
  }

  onToggle = (node, toggled) => {
    if (node.children) {
      node.toggled = toggled;
    }
    this.setState({ data: Object.assign({}, this.state.data) });
  };


  toggleNodeCollapse = (data, key) => {
    const updateNode = (node) => {
      if (node.key === key) {
        return { ...node, collapsed: !node.collapsed };
      } else if (node.children) {
        return { ...node, children: node.children.map(updateNode) };
      }
      return node;
    };

    return { ...data, children: data.children.map(updateNode) };
  };

  resetState() {
    this.setState(InitialState.Account)
  }
  componentWillUnmount() {
    this.resetState()
  }

  disconnect() {
    this._web3Instance.disconnect()
    this.resetState()
  }

  checkConnection() {
    if (isConnected()) {
      this.load()
    } else this.props.history.push('/login')
  }

  async componentDidMount() {
    this._web3Instance = await new Web3Instance().init()
    this.checkConnection()
  }

  copyAddr() {
    navigator.clipboard.writeText(this.state.account)
    message.success({ content: '¡Cuenta copiada!', key: this.state.account, duration: 3 })
  }

  async load() {
    this.setState({
      loading: true,
      account: this._web3Instance.getAccount()

    }, async () => {
      const MetaDapp = await MetaDappContract(this._web3Instance.getProvider())
      this._MetaDappFactory = new MetaDappFactory(MetaDapp)

      try {
        const userData = await this._MetaDappFactory._getUser(this.state.account);
        const userId = userData.userId;
        this.setState({

          totalProducts: (await this._MetaDappFactory._totalProducts()),
          totalUsers: (await this._MetaDappFactory._totalUsers()),
          data: JSON.parse(await this._MetaDappFactory._getUserTree(userId)),
        })

        this.setState({
          loading: false,
        });
      } catch (error) {
        if (error.message.includes('execution reverted: User not found')) {
          this.setState({
          }, () => {
            this.setState({
              loading: false,
            });
            this.props.history.push('/register')
          })

        }
      }

    })

  }

  async onPreview(file) {
    let src = file.url
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader()
        reader.readAsDataURL(file.originFileObj)
        reader.onload = () => resolve(reader.result)
      })
    }
    const image = new Image()
    image.src = src
    const imgWindow = window.open(src)
    imgWindow.document.write(image.outerHTML)
  }


  render() {
    const { loading } = this.state;
    const data2 = {
      nodes: [
        { id: 'Alice', img: 'login2.webp' },
        { id: 'Bob', img: 'login2.webp' },
        { id: 'Carol', img: 'login2.webp' }
      ],
      links: [
        { source: 'Alice', target: 'Bob' },
        { source: 'Bob', target: 'Carol' },
        { source: 'Carol', target: 'Alice' }
      ]
    };
    if (loading) {
      return (
        <div className="loading-spinner-container">
          <Spin className="loading-spinner" size="large" />
        </div>
      );
    }
    if (isConnected())

      return <>
        <div id="treeWrapper" style={{
          width: '100%', height: '100%', display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          border: '1px solid #EABE3F',
          paddingBottom: '1rem',
          backgroundColor: '#262626'
        }}>
          <Tree data={this.state.data}
            orientation="vertical"
            transform="translate(100,7) scale(1)"
            rootNodeClassName="node__root"
            branchNodeClassName="node__branch"
            leafNodeClassName="node__leaf"
            translate={{ x: 100, y: 50 }}
          />
        </div>

      </>
    else return <Login />
  }
}