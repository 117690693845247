import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import 'antd/dist/antd.dark.css'
import { Layout, Button, message } from 'antd'

import '../App.scss'

import {
    LogoutOutlined,
} from '@ant-design/icons'
import { isConnected } from '../util/metadapp'
import { Web3Instance } from '../util/web3Instance'
import { InitialState } from '../util/consts'
import MetaDappContract from '../contracts/CashGroup'
import MetaDappFactory from '../factory/metadappfactory'
const { Header } = Layout

class TheHeader extends Component {
    constructor(props) {
        super(props)
        this.state = InitialState.TheHeader
    }

    displayedAccount() {
        const account = this.state.account
        return `${account.slice(0, 4)}...${account.slice(-4)}`
    }

    
    componentWillUnmount() {
        this.resetState()
    }

    disconnect() {
        this._web3Instance.disconnect()
        this.resetState()
        this.props.history.push('/login')
    }

    resetState() {
        this.setState(InitialState.TheHeader)
    }
    async connect() {
        await this._web3Instance.connect()
        this.checkConnection(false)
    }

    async checkConnection(connect) {
        if (isConnected()) {
            if (connect)
                await this._web3Instance.connect()
            this.load()
        } else this.props.history.push('/login')
    }

    async componentDidMount() {
        
        this._web3Instance = await new Web3Instance().init()
        this.checkConnection(true)
    }

    async load() {
        
        const urlParams = new URLSearchParams(window.location.search);
        const idSponsor = urlParams.get('id');
  
        let idValueSponsor2 = localStorage.getItem('idValueSponsor');
        if (!idValueSponsor2) {
            if (idSponsor) {
                idValueSponsor2 = idSponsor;
              } else {
                idValueSponsor2 = '0';
              }
            localStorage.setItem('idValueSponsor', idValueSponsor2);
        }
        this.setState({
            account: this._web3Instance.getAccount(),
        }, async () => {
            const MetaDapp = await MetaDappContract(this._web3Instance.getProvider())
            this._MetaDappFactory = new MetaDappFactory(MetaDapp)

            try {
                const usuario = await this._MetaDappFactory._getUser(this.state.account)
                console.log(usuario)

                if (usuario) {
                    this.setState(
                        {
                            displayAccount: this.displayedAccount(),
                        },
                        () => {
                            this.props.history.push('/buy');
                        }
                    );
                  } 


            } catch (error) {
                if (error.message.includes('execution reverted: User not found')) {
                    this.setState(
                        {
                            displayAccount: this.displayedAccount(),
                        },
                        () => {
                            const idValueSponsor = localStorage.getItem('idValueSponsor');
                            this.props.history.push(`/register?id=${idValueSponsor}`);
                        }
                    );
                } 
            }
        });
    }


    copyAddr() {
        navigator.clipboard.writeText(this.state.account)
        message.success({ content: '¡Cuenta copiada!', key: this.state.account, duration: 3 })
    }

    render() {
        return (
            <Header className="site-layout-background" style={{ position: 'fixed', zIndex: 1, width: '100%' }} >
                <Button type="primary" shape="round"
                    onClick={async () => isConnected() ? this.disconnect() : this.connect()}>
                    {this.state.displayAccount || 'Login'}
                    {this.state.displayAccount ? <LogoutOutlined /> : <></>}
                </Button>
            </Header>
        )
    }
}

export default withRouter(TheHeader)
