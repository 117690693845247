import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { isConnected, _isPurchased } from '../util/metadapp';
import { InitialState } from '../util/consts';
import { Web3Instance } from '../util/web3Instance';
import MetaDappContract from '../contracts/CashGroup';
import MetaDappFactory from '../factory/metadappfactory';
import Login from './Login';
import './Buy.scss';
import { Row, Col, Card, Button, Result } from 'antd';
import { _bnbToWei, _toBigNumber, _weiToBNB } from '../util/units';

import idiomas_curso1 from '../assets/course/course1/imgs/idiomas_curso1.webp';

import marketing_curso1 from '../assets/course/course1/imgs/marketing_curso1.webp';
import marketing_curso2 from '../assets/course/course1/imgs/marketing_curso2.webp';

import trading_curso1 from '../assets/course/course1/imgs/trading_curso1.webp';
import trading_curso2 from '../assets/course/course1/imgs/trading_curso2.webp';

import programming_curso1 from '../assets/course/course1/imgs/programming_curso1.webp';
import programming_curso2 from '../assets/course/course1/imgs/programming_curso2.webp';
import programming_curso3 from '../assets/course/course1/imgs/programming_curso3.webp';

class Learning extends Component {
    constructor(props) {
        super(props);
        this.state = InitialState.NextGameList;
    }

    checkConnection() {
        if (isConnected()) {
            this.load();
        } else this.props.history.push('/login');
    }

    async componentDidMount() {
        this._web3Instance = await new Web3Instance().init();
        this.checkConnection();
    }

    disconnect() {
        this._web3Instance.disconnect();
        this.resetState();
    }

    async load() {
        this.setState({
            account: this._web3Instance.getAccount()
        }, async () => {
            const MetaDapp = await MetaDappContract(this._web3Instance.getProvider());
            this._MetaDappFactory = new MetaDappFactory(MetaDapp);
            try {
                this.setState({
                    userData: await this._MetaDappFactory._getUser(this.state.account)
                });
            } catch (error) {
                if (error.message.includes('execution reverted: User not found')) {
                    this.setState({}, () => {
                        this.props.history.push('/register');
                    });
                }
            }
            this.setState({
                data: (await this._MetaDappFactory._getProducts()),
                allowance: (await this._web3Instance.allowance(this.state.account, this._MetaDappFactory._address()))
            }, () => {
                this.props.history.push('/learning');
            });
        });
    }

    render() {
        const { loading, userData } = this.state;
        const courses = [
            { title: "NEGOCIOS", courses: ["Course1", "Course2"] },
            { title: "TRADING", courses: ["Trading1", "Trading2"] },
            { title: "IDIOMAS", courses: ["Idiomas1"] },
            { title: "PROGRAMACION", courses: ["Aplicaciones con Flutter", "Aplicaciones Android"] }
        ];

        const images = {
            NEGOCIOS: [marketing_curso1, marketing_curso2],
            TRADING: [trading_curso1, trading_curso2],
            IDIOMAS: [idiomas_curso1],
            PROGRAMACION: [programming_curso2, programming_curso3]
        };

        const urls = {
            NEGOCIOS: ["Course", "Course"],
            TRADING: ["Trading", "Trading"],
            IDIOMAS: ["Idiomas"],
            PROGRAMACION: ["programming", "programming"]
        };

        if (loading) {
            return (
                <div className="loading-spinner-container">
                    <Spin className="loading-spinner" size="large" />
                </div>
            );
        }

        if (!isConnected()) {
            return <Login />;
        }

        if (userData.hasPurchased) {
            return (
                <div className="background-image">
                
                    {courses.map((category, index) => (
                        <div key={index}>
                            <br></br><br></br>
                            <h2 style={{ fontSize: '22px', fontWeight: 'bold' }}>{category.title}:</h2>
                            <Row gutter={[16, 16]}>
                                {category.courses.map((course, i) => (
                                    <Col key={i} xs={24} sm={12} md={8} lg={6}>
                    
                                        <Card
                                            hoverable
                                            style={{ width: '90%' }}
                                            cover={<img alt="example" src={images[category.title][i]} />}
                                        >
                                            <h3>{course}</h3>
                                            <Button
                                                type="primary"
                                                shape="round"
                                                onClick={() => {
                                                    this.props.history.push(`/${urls[category.title][0]}${i + 1}/${i + 1}`);

                                                }}
                                            >
                                                Ver Curso {i + 1}
                                            </Button>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    ))}
                </div>
            );
        } else {
            return (
                <div className="background-image">
                    <Result
                        status="warning"
                        title="Se requiere la compra del paquete"
                        subTitle="Para acceder a nuestra academia educativa, por favor completa el pago. Muchas gracias por elegirnos!!."
                    />
                </div>
            );
        }
    }
}
export default withRouter(Learning);