import React, { useState } from 'react';
import { Link, useLocation, withRouter } from 'react-router-dom'
import 'antd/dist/antd.dark.css'
import { Layout, Menu } from 'antd'
import Icon from '@ant-design/icons'
import './NavItems.scss'
const { Sider } = Layout
function NavItems() {
    const [appData, setAppData] = useState({
        collapsed: true,
        appLogo: '/logo2.webp',
        logo: 'min'
    })
    const onCollapse = () => {
        setAppData({
            collapsed: !appData.collapsed,
            logo: !appData.collapsed ? 'min' : 'max',
            appLogo: !appData.collapsed ? '/logo2.webp' : '/logo.webp'

        })
    }
    return (
        <Sider collapsible collapsed={appData.collapsed} onCollapse={onCollapse} style={{ padding: 'px 0 0 0' }}>
            <a href="/">
                <div className={appData.logo}  >
                    <img className={appData.logo} src={appData.appLogo} />
                </div>
            </a>
            <Menu theme="dark" defaultSelectedKeys={[getSelectedItem()]} mode="inline">

                <Menu.Divider className="main-menu-divider-all" />

                <Menu.Item key="1" icon={<Icon component={() => (<img className="img-panel" src="/dashboard.webp" />)} />}>
                    Dashboard
                    <Link to="/dashboard" />
                </Menu.Item>
                <Menu.Item key="2" icon={<Icon component={() => (<img className="img-panel" src="/buy.webp" />)} />}>
                    Tienda
                    <Link to="/buy" />
                </Menu.Item>
                <Menu.Item key="4" icon={<Icon component={() => (<img className="img-panel" src="/profile.webp" />)} />}>
                    Academia
                    <Link to="/learning" />
                </Menu.Item>
                <Menu.Item key="3" icon={<Icon component={() => (<img className="img-panel" src="/team.webp" />)} />}>
                    Equipo
                    <Link to="/team" />
                </Menu.Item>
            </Menu>
        </Sider>
    );
}

function getSelectedItem() {
    return {
        '/': '1',
        '/dashboard': '1',
        '/buy': '2',
        '/team': '3',
        '/learning': '4'
    }[window.location.pathname.replace('/', '').trim().toLowerCase()]
}

export default withRouter(NavItems)