import React, { useState, useEffect } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { Row, Button} from 'antd'
import 'pdfjs-dist/build/pdf.worker.min';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import 'pdfjs-dist/web/pdf_viewer.css';
import './Course.scss'
import ReactH5AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

function Course1() {
  const [selectedPDF, setSelectedPDF] = useState(null);
  const [pdfs, setPDFs] = useState([]);


  useEffect(() => {
    const loadPDFs = async () => {
      const pdf0 = await import('../assets/course/course1/INTRODUCCION.pdf');
      const pdf1 = await import('../assets/course/course1/CAPITULO 1.pdf');
      const pdf2 = await import('../assets/course/course1/CAPITULO 2.pdf');
      const pdf3 = await import('../assets/course/course1/CAPITULO 3.pdf');
      const pdf4 = await import('../assets/course/course1/CAPITULO 4.pdf');
      const pdf5 = await import('../assets/course/course1/CAPITULO 5.pdf');
      const pdf6 = await import('../assets/course/course1/CAPITULO 6.pdf');
      const pdf7 = await import('../assets/course/course1/CAPITULO 7.pdf');
      const pdf8 = await import('../assets/course/course1/CAPITULO 8.pdf');
      const pdf9 = await import('../assets/course/course1/CAPITULO 9.pdf');
      const pdf10 = await import('../assets/course/course1/CAPITULO 10.pdf');
      const pdf11 = await import('../assets/course/course1/CAPITULO 11.pdf');
      const pdf12 = await import('../assets/course/course1/CAPITULO 12.pdf');
      const pdf13 = await import('../assets/course/course1/CHATGPT.pdf');
      setPDFs([pdf0.default, pdf1.default, pdf2.default, pdf3.default, pdf4.default, pdf5.default, pdf6.default, pdf7.default, pdf8.default, pdf9.default, pdf10.default, pdf11.default, pdf12.default, pdf13.default]);
    };

    loadPDFs();
  }, []);



  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: () => [],
    toolbarPlugin: {
      downloadPlugin: {
        downloadLabel: () => null,
      },
      printPlugin: {
        PrintButton: () => null,
      },
      moreActionsPlugin: {
        MoreActionsButtonContent: () => null,
        MoreActionsPopover: () => null,
      },
    },
    toolbarSlot: {
      rightPanel: () => <></>,
    },
  });

  const openPDFViewer = (pdf) => {
    setSelectedPDF(pdf);
  };

  const closePDFViewer = () => {
    setSelectedPDF(null);
  };

  const pdfAudioMap = {
    '0': '/audio/Introduccion_F.mp3',
    '1': '/audio/capitulo1.mp3',
    '2': '/audio/cap2.mp3',
    '3': '/audio/capitulo3.mp3',
    '4': '/audio/capitulo4.mp3',
    '5': '/audio/capitulo5.mp3',
    '6': '/audio/capitulo6.mp3',
    '7': '/audio/capitulo7.mp3',
    '8': '/audio/capitulo8.mp3',
    '9': '/audio/capitulo9.mp3',
    '10': '/audio/capitulo10.mp3',
    '11': '/audio/capitulo11.mp3',
    '12': '/audio/capitulo12.mp3',
    '13': '/audio/chatgpt.mp3',
    // Agrega más asociaciones según sea necesario
  };

  // Usar una URL predeterminada si no se encuentra una coincidencia

  if (selectedPDF) {

    const pdfPosition = pdfs.findIndex((pdf) => pdf === selectedPDF);
    const audioUrl = pdfAudioMap[pdfPosition] || '';
    return (
      <div className="viewer">
        <button className="close-button" onClick={closePDFViewer}>Close</button>

        <div className="App">
          <ReactH5AudioPlayer
            src={audioUrl}
            autoPlay={false} // Puedes cambiarlo a true si deseas que comience a reproducirse automáticamente
            style={{ maxWidth: '300px' }} // Personaliza el estilo según tus necesidades
          />
        </div>

        <div className="pdf-container"> {/* Agregar el div adicional */}
          <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.6.172/pdf.worker.js">
            <Viewer
              fileUrl={selectedPDF}
              plugins={[defaultLayoutPluginInstance]} />
          </Worker>
        </div>
        <button className="close-button" onClick={closePDFViewer}>Close</button>
        <br></br>

      </div>
    );
  }

  return (
    <div className="background-image">

      <div className="container">
        {/* View PDF */}
        <div className="viewer">
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            {pdfs.map((pdf, index) => (
              <div className="course" key={index}>
                <h3>Module {index + 1}</h3>
                <div className="preview-image-container">
                  <img
                    src={"../imgs/c" + index + ".webp"}
                    alt={`Modulo ${index + 1}`}
                    onClick={() => openPDFViewer(pdf)}
                    style={{ maxWidth: '95%', cursor: 'pointer' }}
                  />
                </div>
              </div>
            ))}
          </Row>
        </div>
      </div>
    </div>
  );
}

export default Course1;
