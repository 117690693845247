import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { isConnected, _isPurchased } from '../util/metadapp'
import { InitialState } from '../util/consts'
import { Web3Instance } from '../util/web3Instance'

import MetaDappContract from '../contracts/CashGroup'

import MetaDappFactory from '../factory/metadappfactory'

import Login from './Login'

import './Buy.scss'
import { Carousel, Row, Card, Popover, Modal, Tag, Button, message } from 'antd'
import { MailOutlined, CopyOutlined, CheckSquareOutlined } from '@ant-design/icons'
import { _bnbToWei, _toBigNumber, _weiToBNB } from '../util/units'

class Buy extends Component {
    constructor(props) {
        super(props)
        this.state = InitialState.NextGameList
    }

    checkConnection() {
        if (isConnected()) {
            this.load()
        } else this.props.history.push('/login')
    }

    async componentDidMount() {
        this._web3Instance = await new Web3Instance().init()
        this.checkConnection()
    }

    disconnect() {
        this._web3Instance.disconnect()
        this.resetState()
    }

    async load() {
        this.setState({
            account: this._web3Instance.getAccount()
        }, async () => {

            const MetaDapp = await MetaDappContract(this._web3Instance.getProvider())
            this._MetaDappFactory = new MetaDappFactory(MetaDapp)
            try {
                this.setState({
                    userData: await this._MetaDappFactory._getUser(this.state.account)
                })
            } catch (error) {
                if (error.message.includes('execution reverted: User not found')) {
                    this.setState({
                    }, () => {
                        this.props.history.push('/register')
                    })

                }
            }
            this.setState({
                data: (await this._MetaDappFactory._getProducts()),
                allowance: (await this._web3Instance.allowance(this.state.account, this._MetaDappFactory._address()))
            }, () => {
                this.props.history.push('/buy')

            })

        })
    }

    itemDetailsVisibility = async (visible, account) => {
        if (visible) {
            this.setState({
                itemDetailsContact: (await this._MetaDappFactory._getUser(account)).contact
            })
        } else {
            this.setState({
                itemDetailsContact: undefined
            })
        }
    }

    popContent(item) {
        return (
            <div className="popover">
                <p>{item.desc}</p>
                <Tag
                    icon={<CheckSquareOutlined />}
                    color={'success'}>
                    {'Enabled'}
                </Tag>
                {this.state.itemDetailsContact ?
                    <span
                        onClick={() => this.copyAddr()}
                        className="user-account-header__item-contact">
                        <CopyOutlined />
                    </span>
                    : ''}
            </div>
        )
    }


    getOpenPurchaseButton(item, hasRepurchased) {
        
        const isButtonEnabled = !hasRepurchased;
        return (
            <Button
                type="primary"
                shape="round"
                disabled={!isButtonEnabled} 
                onClick={async () => this.selectItem(item)}
            >
                {parseInt(_weiToBNB(item.price) - 2)} {this.state.token_symbol}
            </Button>
        )
    }

    async selectItem(item) {
        this.setState({
            allowance: (await this._web3Instance.allowance(this.state.account, this._MetaDappFactory._address())),
            itemToBuy: item,
            isModalVisible: true
        })
    }

    async approve() {
        const { userData } = this.state;
        try {
            const hideLoad = message.loading(`Aprobando ${_weiToBNB(this.state.itemToBuy.price)} ${this.state.token_symbol}, Espere un momento por favor...`, 0);

            const result = await this._web3Instance.approveTokens(this._MetaDappFactory._address(), this.state.itemToBuy.price, this.state.account);

            this.setState({
                allowance: (await this._web3Instance.allowance(this.state.account, this._MetaDappFactory._address()))
            }, () => {
                hideLoad();
                if (result === '200') {
                    message.success({ content: `${_weiToBNB(this.state.itemToBuy.price)} ${this.state.token_symbol} aprobado exitosamente!`, key: this.state.account, duration: 2 });
                } else {
                    message.error({ content: 'Se produjo un error al aprobar el USDT, intentalo de nuevo o valida que tengas bnb suficientes.', key: this.state.account, duration: 2 });
                }
            });
        } catch (err) {
            hideLoad();
            message.error({ content: 'Se ha producido un error.', key: this.state.account, duration: 2 });
        }
    }

    async buyItem() {
        const hideLoad = message.loading(`Comprando ${this.state.itemToBuy.name}, Espere un momento por favor...`, 0)
        const { userData } = this.state;
        try {
            await this._MetaDappFactory._buyProduct(this.state.itemToBuy.id, userData.userId, this.state.account)
            this.setState({
                isModalVisible: false,
                data: (await this._MetaDappFactory._getProducts())
            }, () => {
                hideLoad()
                message.success({ content: `${this.state.itemToBuy.name} comprado correctamente!`, key: this.state.account, duration: 5 })
                this.load()
            })
        } catch (err) {
            hideLoad()
            message.error({ content: `Se ha producido un error`, key: this.state.account, duration: 2 })
        }
    }

    getApproveButton() {
        return (this.state.itemToBuy.price > this.state.allowance ?
            <Button key="approve" shape="round" type="primary" onClick={() => { this.approve() }}>Aprobar</Button> :
            <Button disabled key="approve" shape="round" type="primary">Aprobar</Button>)
    }

    getPurchaseButton() {
        return (this.state.itemToBuy.price <= this.state.allowance ?
            <Button key="purchase" shape="round" type="primary" onClick={() => { this.buyItem() }}>Comprar </Button> :
            <Button disabled key="purchase" shape="round" type="primary">Comprar </Button>
        )
    }

    render() {
        const { userData } = this.state;

        if (isConnected()) {
            return (
                <>
                    <div className="background-image">
                        <Row span={12} offset={6}>
                            {this.state.data.map((pass, index) => {
                                let img;
                                if (pass.id === 0) {
                                    if (userData.hasPurchased === false) {
                                        img = "Cashgif.gif";
                                    } else {
                                        img = "6spaces.webp";
                                    }
                                    return (
                                        <Card
                                            key={index}
                                            hoverable
                                            style={{ width: 500 }}
                                            cover={<img alt="example" src={img} />}
                                        >
                                            <Popover
                                                onOpenChange={async (visible) => this.itemDetailsVisibility(visible, pass.owner)}
                                                content={this.popContent(pass)}
                                                title={pass.name}
                                                trigger="click"
                                            >
                                                <Card.Meta title={pass.name} />
                                            </Popover>
                                            <p></p>
                                            {this.getOpenPurchaseButton(pass, userData.hasRepurchased)}
                                        </Card>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        </Row>
                    </div>
                    <Modal
                        footer={[
                            this.getApproveButton(),
                            this.getPurchaseButton(),
                        ]}
                        title="SET APP"
                        open={this.state.isModalVisible}
                        onCancel={() => {
                            this.setState({
                                isModalVisible: false,
                            });
                        }}
                    >
                        <Row>
                            <span className="buy">
                                ¿Comprar el paquete {this.state.itemToBuy.name} en {_weiToBNB(this.state.itemToBuy.price)} USD?
                            </span>
                            <span>Recuerda que el valor del paquete es de 2 usd.</span>
                        </Row>
                    </Modal>
                </>
            );
        } else {
            return <Login />;
        }
    }
}

export default withRouter(Buy)