import React, { Component } from 'react'
import 'antd/dist/antd.dark.css';
import { Layout } from 'antd';
import '../App.scss'

const { Footer } = Layout

export default class TheFooter extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <Footer className="footer" style={{ textAlign: 'center' }}><img style={{ width: '45px', height: "auto" }} src="/logo2.webp" /> set-app.ai ©2020 Powered by @set-app</Footer>
        )
    }
}