import React, { Component } from 'react'
import 'antd/dist/antd.dark.css'
import {
    Descriptions,
    Badge,
    Card,
    Button,
    Row,
    Tag,
    Statistic,
    message,
    Modal,
    Input,
    Select,
    Upload, Progress, Space,
    Col, Spin
} from 'antd'

import {
    ArrowUpOutlined,
    CopyOutlined,
    EditOutlined,
    UserOutlined,
    MailOutlined
} from '@ant-design/icons'



import './Dashboard.scss'
import { Web3Instance } from '../util/web3Instance'
import MetaDappContract from '../contracts/CashGroup'
import MetaDappFactory from '../factory/metadappfactory'
import { _sections, _base64, _mapImagesProduct, isConnected, bnbPrice } from '../util/metadapp'
import { saveImages, _saveImages } from '../util/imagesaver'

import Login from './Login'

import { InitialState } from '../util/consts'
import ipfs from '../storage/ipfs'
import { _weiToBNB } from '../util/units'



export default class Dashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ...InitialState.Account,
            loading: true,
            account: null,
            userData: null,
            totalProducts: null,
            totalUsers: null,
        };
    }

    componentWillUnmount() {
        this.resetState()
    }

    disconnect() {
        this._web3Instance.disconnect()
        this.resetState()
    }

    resetState() {
        this.setState(InitialState.Account)
    }

    checkConnection() {
        if (isConnected()) {
            this.load()
        } else this.props.history.push('/login')
    }

    async componentDidMount() {
        this._web3Instance = await new Web3Instance().init()
        this.checkConnection()
    }

    displayedAccount() {
        const account = this.state.account
        return `${account.slice(0, 4)}...${account.slice(-4)}`
    }

    copyAddr() {
        navigator.clipboard.writeText(this.state.account)
        message.success({ content: '¡Cuenta copiada!', key: this.state.account, duration: 3, })
    }

    copyLink() {
        const domainUrl = window.location.origin;
        const { userData } = this.state;
        const textField = document.createElement('input');
        const username = userData.username;
        const encodedUsername = username.replace(/\s/g, '%20');
        textField.setAttribute('type', 'text');
        textField.setAttribute('value', domainUrl + '/login?id=' + encodedUsername);
        textField.setAttribute('readonly', true);
        textField.style.position = 'absolute';
        textField.style.left = '-9999px';
        document.body.appendChild(textField);
        textField.select();
        textField.setSelectionRange(0, 99999); // Para dispositivos móviles
        document.execCommand('copy');
        document.body.removeChild(textField);

        message.success({ content: 'Link copied!', key: this.state.account, duration: 3 });
    }

    async load() {
        this.setState({
            loading: true,
            account: this._web3Instance.getAccount()
        }, async () => {

            const MetaDapp = await MetaDappContract(this._web3Instance.getProvider())
            this._MetaDappFactory = new MetaDappFactory(MetaDapp)


            try {
                this.setState({
                    userData: await this._MetaDappFactory._getUser(this.state.account)
                })
            } catch (error) {

                if (error.message.includes('execution reverted: User not found')) {
                    this.setState({
                    }, () => {
                        this.setState({
                            loading: false,
                        });
                        this.props.history.push('/register')
                    })

                }
            }
            this.setState({
                totalProducts: (await this._MetaDappFactory._totalProducts()),
                totalUsers: (await this._MetaDappFactory._totalUsers()),
                userData: (await this._MetaDappFactory._getUser(this.state.account)),
                displayAccount: this.displayedAccount(),

            }, () => {
                this.setState({
                    loading: false,
                });

                this.props.history.push('/dashboard')

            })

        })
    }

    render() {
        const { loading, userData } = this.state;
        if (loading) {
            return (
                <div className="loading-spinner-container">
                    <Spin className="loading-spinner" size="large" />
                </div>
            );
        }

        let hasRepurchased;
        let status;
        let rebuyIndicator = userData.rebuyIndicator;
        let advance = parseFloat(((rebuyIndicator * 100) / 6).toFixed(2));

        if (userData.hasRepurchased) {
            hasRepurchased = "Si";
            status = <Badge status="error" text="Inactivo" />;
        }
        else {
            hasRepurchased = "No";
            status = <Badge status="success" text="Activo" />;
        }
        if (isConnected())
            return <>

                <Descriptions title="INFORMACION PERSONAL" size="large" layout="vertical" bordered>
                    <Descriptions.Item label="Avance"><Space wrap>

                        <Progress type="circle" percent={advance} strokeColor={{ '4%': '#ffb539', '100%': '#cc840d' }} />
                        <div>{`Faltan ${6 - rebuyIndicator} Espacios para completar el bloque`}</div>
                    </Space>
                    </Descriptions.Item>
                    <Descriptions.Item label="Username"> {userData.username}</Descriptions.Item>
                    <Descriptions.Item label="Link de referido"> <h2> {this.state.displayAccount}
                        {this.state.account ?
                            <span
                                onClick={() => this.copyLink()}
                                className="user-account-header">
                                <CopyOutlined className="icon-ref" />
                            </span>
                            : ''}
                    </h2></Descriptions.Item>

                    <Descriptions.Item label="# Referidos directos">{userData.referralsCount}</Descriptions.Item>
                    <Descriptions.Item label="# Paquetes comprados">{userData.totalPackage}</Descriptions.Item>
                    <Descriptions.Item className="user-important" label="Comisiones ganadas">{_weiToBNB(userData.totalCommissions)} USD</Descriptions.Item>
                    <Descriptions.Item label="Tienes que renovar tu franquicia?">{hasRepurchased}</Descriptions.Item>

                    <Descriptions.Item label="Status">
                        {status}

                    </Descriptions.Item>
                </Descriptions>
                <div className="background-image">
                </div>
            </>
        else return <Login />
    }
}