
import React, { useState } from 'react';
import { Row, Col, Spin } from 'antd';
import ReactPlayer from 'react-player';
import './Course.scss';



function Course2() {
  const [selectedModule, setSelectedModule] = useState(null);
  const [loading, setLoading] = useState(false);

  const modules = [
    {
      title: "¡Bienvenido al Curso 'Diseño de Páginas de Venta' 🌐✨ ",
      description: "Descubre los secretos detrás de páginas web irresistibles y estrategias de conversión efectivas. Este curso te guiará desde los fundamentos hasta tácticas avanzadas. <br>" +

        "Bonus Exclusivo 🎁: Descarga más de 1000 plantillas de páginas de venta para potenciar tu creatividad. <br><br>" +
        '<a href="https://dashboard.cash-group.io/plantillas/+%2010.000%20P%C3%81GINAS%20DE%20VENTAS.zip" download>Descargar Archivo con las plantillas</a><br><br>',


      videos: [
        { url: 'https://player.vimeo.com/video/883286889', title: 'Introducción' }
      ]
    },
    {
      title: "Módulo 1 - *Fundamentos Básicos*: 🌱",
      description: "Este módulo establece los pilares fundamentales para tu comprensión del diseño web y las ventas en línea. Desde la terminología esencial hasta los principios básicos, aquí construirás tu base para el resto del curso.",
      videos: [
        { url: 'https://player.vimeo.com/video/883439692', title: 'Funnel de ventas' },
        { url: 'https://player.vimeo.com/video/883444076', title: 'Herramientas' }
      ]
    },
    {
      title: "Módulo 2 -  *Preparando el Entorno*: 🖥",
      description: "Adéntrate en la configuración y preparación de tu espacio digital. Desde la elección del hosting hasta la creación de un entorno amigable para el usuario, este módulo garantiza que tu página web de ventas esté listo para destacar.",
      videos: [
        { url: 'https://player.vimeo.com/video/881430104', title: 'Preparando el entorno' }
      ]
    },
    {
      title: "Módulo 3 - *Integración de las Herramientas*: 🔧",
      description: "Descubre cómo integrar herramientas digitales clave en tu página web de ventas. Desde herramientas de análisis hasta plugins esenciales, aprenderás a aprovechar al máximo la tecnología disponible.",
      videos: [
        { url: 'https://player.vimeo.com/video/883017161', title: 'Instalación de herramientas' },
        { url: 'https://player.vimeo.com/video/883020922', title: 'Seguridad' },
        { url: 'https://player.vimeo.com/video/883022572', title: 'Instalacion de Elementor' }

      ]
    },
    {
      title: "Módulo 4 - *Diseñando el Funnel*: 🔄 ",
      description: "Este módulo se sumerge en el arte del diseño de embudos de ventas efectivos. Desde la atracción inicial hasta la conversión, aprenderás a guiar a tus visitantes a través de un proceso que maximiza las conversiones.",
      videos: [
        { url: 'https://player.vimeo.com/video/883024116', title: 'Secciones en Elementor' },
        { url: 'https://player.vimeo.com/video/883026219', title: 'Colores y Tipografia' },
        { url: 'https://player.vimeo.com/video/883028950', title: 'Elementos de Elementor' },
        { url: 'https://player.vimeo.com/video/883037945', title: 'Nueva Pagina' },
        { url: 'https://player.vimeo.com/video/883031917', title: 'Resultado Final' }
      ]
    },
    {
      title: "Módulo 5 -  *Integración de Pasarela de Pagos*: 💳",
      description: "Facilita a tus clientes el proceso de compra integrando pasarelas de pago seguras. Aprenderás a configurar opciones de pago sin problemas, asegurando una experiencia de compra confiable.",
      videos: [
        { url: 'https://player.vimeo.com/video/883047257', title: 'Pasarelas de pago' }
      ]
    },
    {
      title: "Módulo 6 -  *Estrategia de Ventas con el Funnel*: 📈",
      description: "Profundiza en estrategias específicas para potenciar tus ventas a lo largo del embudo. Desde técnicas de persuasión hasta ofertas irresistibles, este módulo te preparará para convertir visitantes en clientes satisfechos.",
      videos: [
        { url: 'https://player.vimeo.com/video/883049437', title: 'Estrategia del Funnel' }
      ]
    },
    {
      title: "Módulo 7 - *Adquisición de Clientes sin Publicidad*: 🚀",
      description: "Explora técnicas orgánicas para atraer clientes, reduciendo la dependencia de la publicidad pagada. Desde el SEO hasta el marketing de contenidos, descubre cómo generar tráfico de calidad sin grandes inversiones publicitarias.",
      videos: [
        { url: 'https://player.vimeo.com/video/883051313', title: 'Adquisición de Clientes' }
      ]
    },
    {
      title: "Módulo 8 - *Crea Tu Propia Agencia Digital*: 🏢",
      description: "Da un paso más allá al aprender a establecer y hacer crecer tu propia agencia digital. Desde la estructuración hasta la adquisición de clientes, este módulo te brinda las herramientas para emprender en el mundo digital.",
      videos: [
        { url: 'https://player.vimeo.com/video/883052516?share=copy', title: 'Agencia Digital' }
      ]
    },
    {
      title: "Módulo 9 - *Certificado de Reconocimiento*: 🏆",
      description: "Al completar con éxito el curso, recibirás un certificado oficial de reconocimiento. Este documento atestigua tu habilidad para diseñar páginas web de ventas efectivas.",
      videos: [
        { url: 'https://player.vimeo.com/video/883053903?share=copy', title: 'Certificado de Reconocimiento' }
      ]
    },
    {
      title: "Módulo 10 - *Término del Curso*: 🎓",
      description: "Celebra tu logro educativo y la culminación de tu viaje de aprendizaje. Equipado con conocimientos avanzados en diseño web para ventas, estás listo para triunfar en el mundo digital.",
      videos: [
        { url: 'https://player.vimeo.com/video/883055004', title: 'Despedida' }
      ]
    }
  ];

  const openModule = (module) => {
    setLoading(true);
    setSelectedModule(module);
    setLoading(false);
  };

  const closeModule = () => {
    setSelectedModule(null);
  };

  if (selectedModule) {
    return (
      <div className="module-details">

        <button className="close-button" onClick={closeModule}>Cerrar</button>
        <Col xs={24} sm={12}>
          <h2>{selectedModule.title}</h2>
          <p className="justify-text" dangerouslySetInnerHTML={{ __html: selectedModule.description }} />
        </Col>
        <Row gutter={[16, 16]}>
          {loading ? ( // Mostrar el indicador de carga mientras loading es true
            <Col span={24} style={{ textAlign: 'center' }}>
              <Spin size="large" />
            </Col>
          ) : (
            selectedModule.videos.map((video, index) => (
              <Col xs={24} sm={12} md={8} lg={12} key={index}>
                <div className="video">
                  <h3 style={{ fontSize: '29px', fontWeight: 'bold' }}>{video.title}</h3>
                  <div className="video-container">
                    <iframe
                      src={video.url}
                      frameBorder="1"
                      allow="autoplay; fullscreen; picture-in-picture"
                      style={{ position: 'relative', width: '100%', height: '100%' }}
                      allowFullScreen
                      title={video.title}
                    ></iframe>
                    <script src="https://player.vimeo.com/api/player.js"></script>
                  </div>
                </div>
              </Col>
            ))
          )}
        </Row>
      </div>
    );
  }

  return (
    <div className="background-image">
      <div className="container">
        <Row gutter={[16, 16]}>
          {modules.map((module, index) => (
            <Col xs={24} sm={12} md={4} lg={6} key={index}>
              <div className="course" onClick={() => openModule(module)}>
                <h3>{module.title}</h3>
                <div className="preview-image-container">
                  <img
                    src={`../imgs/course2/c${index}.webp`}
                    alt={`Modulo ${index + 1}`}
                    style={{ maxWidth: '100%', cursor: 'pointer' }}
                  />
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
}

export default Course2;
