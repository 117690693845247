import React, { useState } from 'react';
import { Row, Col, Spin } from 'antd';
import './Course.scss';

function Programming1() {
  const [selectedModule, setSelectedModule] = useState(null);
  const [loading, setLoading] = useState(false);

  const modules = [
    {
      title: "Modulo 1 Dart",
      description: "Dart<br>" ,
      videos: [
        { url: 'https://drive.google.com/file/d/1puyXHJJRF07t1waGvpMZN6SynW49BeF4/preview?disablekb=1&controls=0&modestbranding=1', title: 'Presentación del curos y Objetivos' },
        { url: 'https://drive.google.com/file/d/1O2mqJ0mtENvbqhr1v8l_8Fp3x9flAedd/preview?disablekb=1&controls=0&modestbranding=1', title: 'El lenguaje de programación Dart' },
        { url: 'https://drive.google.com/file/d/1qiWwqAa_tV49jd9FFvq0sc5QFvFhwbgB/preview?disablekb=1&controls=0&modestbranding=1', title: 'Preparar el IDE' },
        { url: 'https://drive.google.com/file/d/17TouAE3hGHk08mBDI0azJfF3bfG6jd6i/preview?disablekb=1&controls=0&modestbranding=1', title: 'Crear y ejecutar el primer proyecto' },
        { url: 'https://drive.google.com/file/d/1yWr1pVlVt9CV-5JTN6gWJyjsAwdXjgfR/preview?disablekb=1&controls=0&modestbranding=1', title: 'Conocimientos Previos' },
        { url: 'https://drive.google.com/file/d/10VN_2ulz99GaBzKFi4Vw_33SLHbIzPni/preview?disablekb=1&controls=0&modestbranding=1', title: 'Varibles' }
      ]
    }
  ];

  const openModule = (module) => {
    setLoading(true);
    setSelectedModule(module);
    setLoading(false);
  };

  const closeModule = () => {
    setSelectedModule(null);
  };

  if (selectedModule) {
    return (
      <div className="module-details">
        <button className="close-button" onClick={closeModule}>Cerrar</button>
        <Col xs={24} sm={12}>
          <h2>{selectedModule.title}</h2>
          <p className="justify-text" dangerouslySetInnerHTML={{ __html: selectedModule.description }} />
        </Col>
        <Row gutter={[16, 16]}>
          {loading ? ( // Mostrar el indicador de carga mientras loading es true
            <Col span={24} style={{ textAlign: 'center' }}>
              <Spin size="large" />
            </Col>
          ) : (
            selectedModule.videos.map((video, index) => (
              <Col xs={24} sm={12} md={8} lg={12} key={index}>
                <div className="video">
                  <h3 style={{ fontSize: '29px', fontWeight: 'bold' }}>{video.title}</h3>
                  <div className="video-container">
                    <iframe
                      src={video.url}
                      frameBorder="0"
                      allowFullScreen
                      title={video.title}
                      width="100%"
                      height="100%"
                      style={{ width: '100%', height: '100%' }}
                    ></iframe>
                  </div>
                </div>
              </Col>
            ))
          )}
        </Row>
      </div>
    );
  }

  return (
    <div className="background-image">
      <div className="container">
        <Row gutter={[16, 16]}>
          {modules.map((module, index) => (
            <Col xs={24} sm={12} md={4} lg={6} key={index}>
              <div className="course" onClick={() => openModule(module)}>
                <h3>{module.title}</h3>
                <div className="preview-image-container">
                  <img
                    src={`../imgs/programming1/c${index}.webp`}
                    alt={`Modulo ${index + 1}`}
                    style={{ maxWidth: '100%', cursor: 'pointer' }}
                  />
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
}

export default Programming1;