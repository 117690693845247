import React, { useState } from 'react';
import { Row, Col, Spin } from 'antd';
import './Course.scss';

function Programming3() {
  const [selectedModule, setSelectedModule] = useState(null);
  const [loading, setLoading] = useState(false);

  const modules = [
    {
      title: "Crea tu propio Jarvis (Parte 1)",
      description: "Crea tu propio Jarvis (Parte 1) <br>",
      videos: [
        { url: 'https://drive.google.com/uc?export=download&1gJFpx-yRn2eR9lD-WMawssuxWuXeON9a', title: '1-bienvenida_al_curso' },
        { url: 'https://drive.google.com/file/d/1CEQGNw1PCcDUgNsKk0zl6et7Gbc47Evo/preview?disablekb=1&controls=0&modestbranding=1', title: 'Agenda' },
        { url: 'https://drive.google.com/file/d/1wOiEK4flF5GUmnXhzpUNy2j1Ob1xR8fX/preview?disablekb=1&controls=0&modestbranding=1', title: 'Materiales' },
        { url: 'https://drive.google.com/file/d/18QSLLAXpdOqhfhrmBkJe2fQywoU6Hpf_/preview?disablekb=1&controls=0&modestbranding=1', title: 'Requerimientos previos' },
        { url: 'https://drive.google.com/file/d/12iivVUJEB02XoZlcKSLCDks7p-UdOpUJ/preview?disablekb=1&controls=0&modestbranding=1', title: 'Aspectos basicos iot' },
        { url: 'https://drive.google.com/file/d/1sDIotI39akkup-23CY-nAWTP3d9IyqN0/preview?disablekb=1&controls=0&modestbranding=1', title: 'Inpacto Insdustrial del iot' },
        { url: 'https://drive.google.com/file/d/1SfHI0Z2w413PP1LbiO1ODYyCiLwjhqR4/preview?disablekb=1&controls=0&modestbranding=1', title: 'Tendencias Tecnologicas' },
        { url: 'https://drive.google.com/file/d/1Ts4DJbAovYKwYjzFwtFkZnd-b_WpwBG_/preview?disablekb=1&controls=0&modestbranding=1', title: 'Estructura de un proyecto ios Voz' },
        { url: 'https://drive.google.com/file/d/12PajJcTGuKJxFzu0_nRDk8Noog3lJtTY/preview?disablekb=1&controls=0&modestbranding=1', title: 'Instalacion de Raspian' },
        { url: 'https://drive.google.com/file/d/1w5-9actTx58eRMk1JY-ZuH7C_JmKz12b/preview?disablekb=1&controls=0&modestbranding=1', title: 'Configuración de inicio' }
      ]
    }
  ];

  const openModule = (module) => {
    setLoading(true);
    setSelectedModule(module);
    setLoading(false);
  };

  const closeModule = () => {
    setSelectedModule(null);
  };

  if (selectedModule) {
    return (
      <div className="module-details">
        <button className="close-button" onClick={closeModule}>Cerrar</button>
        <Col xs={24} sm={12}>
          <h2>{selectedModule.title}</h2>
          <p className="justify-text" dangerouslySetInnerHTML={{ __html: selectedModule.description }} />
        </Col>
        <Row gutter={[16, 16]}>
          {loading ? ( // Mostrar el indicador de carga mientras loading es true
            <Col span={24} style={{ textAlign: 'center' }}>
              <Spin size="large" />
            </Col>
          ) : (
            selectedModule.videos.map((video, index) => (
              <Col xs={24} sm={12} md={8} lg={12} key={index}>
                <div className="video">
                  <h3 style={{ fontSize: '29px', fontWeight: 'bold' }}>{video.title}</h3>
                  <div className="video-container">
                    <iframe
                      src={video.url}
                      frameBorder="0"
                      allowFullScreen
                      title={video.title}
                      width="100%"
                      height="100%"
                      style={{ width: '100%', height: '100%' }}
                    ></iframe>
                  </div>
                </div>
              </Col>
            ))
          )}
        </Row>
      </div>
    );
  }

  return (
    <div className="background-image">
      <div className="container">
        <Row gutter={[16, 16]}>
          {modules.map((module, index) => (
            <Col xs={24} sm={12} md={4} lg={6} key={index}>
              <div className="course" onClick={() => openModule(module)}>
                <h3>{module.title}</h3>
                <div className="preview-image-container">
                  <img
                    src={`../imgs/programming3/c${index}.webp`}
                    alt={`Modulo ${index + 1}`}
                    style={{ maxWidth: '100%', cursor: 'pointer' }}
                  />
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
}

export default Programming3;