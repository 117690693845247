import { hashToURL, _getImages } from "../util/imagesaver"
import axios from 'axios'

export default class MetaDappFactory {
    constructor(contract) {
        this.contract = contract
    }

    _address() {
        return this.contract.address
    }

    async _getUser(account) {

        let user = await this.contract.getUserIdByWallet(account)
        let referralsCount = user.referrals.length;
        return {
            userId: user.userId,
            prod: user.prod,
            username: user.username,
            sponsorId: user.sponsorId,
            referralsCount: referralsCount,
            rebuyIndicator: user.rebuyIndicator,
            referrals: user.referrals,
            hasPurchased: user.hasPurchased,
            hasRepurchased: user.hasRepurchased,
            totalPackage: user.totalPackage,
            totalCommissions: user.totalCommissions
        }
    }

    async _getUserProductsIds(account) {
        return (await this._getUser(account)).products
    }

    async _getProduct(product_id) {
        let product = await this.contract.getProduct(product_id)

        return {
            id: product_id,
            name: product.name,
            dataHash: product.dataHash,
            section: product.section,
            price: product.price,
            owner: product.owner,
            reserved_by: product.reserved_by,
            images: []
        }
    }

    async _getProducts() {
        let products = await this.contract.getProducts()
        return this.mapProducts(products)
    }

    async _totalUsers() {
        return (await this.contract.totalUsers()).toNumber()
    }

    async _totalProducts() {
        return (await this._getProducts()).length
    }

    async _getUserTree(userId) {
        return (await this.contract.getUserTree(userId))
    }

    async _buyProduct(product_id,userId, from) {
        return await this.contract.buyProduct(product_id, userId, { from })
    }

    async _updateUserContact(dataHash, from) {
        return this.contract.updateUserContact(dataHash, { from })
    }

    async _updateProductPrice(product_id, price, from) {
        return await this.contract.updateProductPrice(product_id, price, { from })
    }

    async _addProduct(name, price, from) {
        return await this.contract.addProduct(name, price, { from })
    }

    async _register(account, username, id , from) {
        return await this.contract.register(account, username, id, { from })
    }
    
    async _percentValue(amount, from) {
        return await this.contract.__percentValue(amount, { from })
    }

    mapProducts(products) {
        return products.map((product, product_id) => {
            return {
                id: product_id,
                name: product.name,
                dataHash: product.dataHash,
                section: product.section,
                price: product.price,
                owner: product.owner,
                reserved_by: product.reserved_by,
                images: []
            }
        })
    }
    
}